/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganisationResource
 */
export interface OrganisationResource {
    /**
     * 
     * @type {string}
     * @memberof OrganisationResource
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationResource
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationResource
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationResource
     */
    country?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationResource
     */
    enabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrganisationResource
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationResource
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationResource
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationResource
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationResource
     */
    registrationNumber?: string;
}

export function OrganisationResourceFromJSON(json: any): OrganisationResource {
    return OrganisationResourceFromJSONTyped(json, false);
}

export function OrganisationResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'locality': !exists(json, 'locality') ? undefined : json['locality'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
    };
}

export function OrganisationResourceToJSON(value?: OrganisationResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'country': value.country,
        'enabled': value.enabled,
        'id': value.id,
        'locality': value.locality,
        'name': value.name,
        'postalCode': value.postalCode,
        'registrationNumber': value.registrationNumber,
    };
}


