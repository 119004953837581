import React, {useState, useContext, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddSignerIcon from "@material-ui/icons/PersonAdd";
import SignedIcon from '@material-ui/icons/AssignmentTurnedIn';
import UnSignedIcon from '@material-ui/icons/Assignment';
import ReminderIcon from '@material-ui/icons/AddAlert';
import SignedReportIcon from '@material-ui/icons/PlaylistAddCheck';
import EditIcon from '@material-ui/icons/Edit';
import Select from "@material-ui/core/Select";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import AppContext from './AppContext';
import {checkError, errOptions} from "./util";
import OKCancelDialog from './OKCancelDialog';
import {SignatureRequestControllerApi, DocumentSignatureRequestApi, DocumentControllerApi} from "./api";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    nowrap: {
        'white-space': 'nowrap'
    },
}));

function Document(props) {
    const classes = useStyles()
    const history = useHistory()
    const {enqueueSnackbar} = useSnackbar();

    const {users, user} = useContext(AppContext);
    const [openSelectUser, setOpenSelectUser] = useState(false);
    const [openEditDocument, setOpenEditDocument] = useState(false);
    const [signer, setSigner] = useState(null);
    const [docName, setDocName] = useState(props.document.name);
    const [signatures, setSignatures] = useState(null);
    const [tagId, setTagId] = useState(props.document.tagId);

    useEffect(() => {
        const api = new DocumentSignatureRequestApi(props.conf);
        api.listDocumentSignatures({documentId: props.document.uid})
            .then((s) => {
                setSignatures(s);
            })
            .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to list signature requests: ' + e.statusText,
                    errOptions))
            );
        if (tagId == null) {
            setTagId(0);
        }
    }, []);

    const handleDownloadSigningPage = () => {
        const api = new DocumentControllerApi(props.conf);
        api.signerPage({uid: props.document.uid})
            .then( (file) => {
                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = 'signerpage.pdf';
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            } )
            .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to download signing page: ' + e.statusText,
                    errOptions))
            );
    }

    const handleAddSigner = () => {
        setSigner(props.document.uploader.uid);
        setOpenSelectUser(true);
    }

    const handleUserClose = () => {
        setOpenSelectUser(false);
    }

    const handleEditNameClose = () => {
        setOpenEditDocument(false);
        setDocName(props.document.name);
        if (props.document.tagId != null) {
            setTagId(props.document.tagId);
        } else {
            setTagId(0);
        }
    }

    const handleEditNameAccept = () => {
        setOpenEditDocument(false);
        const api = new DocumentControllerApi(props.conf);
        api.updateDocument({
            uid: props.document.uid,
            resource: {name: docName, tagId: tagId}
        })
            .then()
            .catch((e) => {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to update document name: ' + e.statusText,
                        errOptions));
                setDocName(props.document.name);
            });
    }

    const editDocument = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        id={props.document.uid +"name"}
                        name="name"
                        label="Document Name"
                        value={docName}
                        onChange={(e) => setDocName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor={props.document.uid + 'document-tag'}>Document Tag</InputLabel>
                    <Select
                        native
                        inputProps={{
                            name: props.document.uid +'tag',
                            id: props.document.uid + 'document-tag',
                        }}
                        value={tagId}
                        onChange={(e) => setTagId(Number(e.target.value))}
                    >
                        {props.tags.map((tag) =>
                            <option key={tag.id} value={tag.id}>{tag.name}</option>)
                        }
                        <option key="0" value={0}>None</option>)
                    </Select>
                </Grid>
            </Grid>
        )
    };

    const handleUserAccept = (r) => {
        setOpenSelectUser(false);
        const api = new SignatureRequestControllerApi(props.conf);
        api.createSignatureRequest({
            requestCreateRequest: {
                documentId: props.document.uid,
                userId: signer,
            }
        })
            .then((result) => {
                enqueueSnackbar('Signer added.', {autoHideDuration: 3000, variant: 'success'});
                if (signatures == null) {
                    setSignatures([result])
                } else {
                    setSignatures([...signatures, result])
                }
            })
            .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to add signer: ' + e.statusText,
                    errOptions)));
    }

    const handleUserChange = (r) => {
        setSigner(r.target.value);
    }

    function filterUsers() {
        return users.filter((u) => {
            return signatures == null || !signatures.find(el => el.signer.uid === u.uid)
        });
    }

    const handleRemindSigner = (id) => {
        const api = new SignatureRequestControllerApi(props.conf);
        api.sendSignatureRequestNotification({id: id})
            .then((result) => {
                enqueueSnackbar('Reminder sent.', {autoHideDuration: 3000, variant: 'success'});
            })
            .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to send reminder: ' + e.statusText,
                    errOptions)));
    }

    const getUsersList = () => {
        return (
            <Select
                native
                fullWidth
                value={signer}
                onChange={handleUserChange}
                inputProps={{
                    name: 'user',
                    id: user.uid + 'user-native',
                }}>
                {filterUsers(users).map((user) =>
                    <option key={user.uid} value={user.uid}>{user.name}</option>)
                }
            </Select>
        )
    };

    return (<>
        <OKCancelDialog title="Select signer"
                        description="Select a user to sign this document."
                        render={getUsersList}
                        open={openSelectUser}
                        handleClose={handleUserClose}
                        handleAccept={handleUserAccept}/>

        <OKCancelDialog title="Update document information"
                        description=""
                        render={editDocument}
                        open={openEditDocument}
                        handleClose={handleEditNameClose}
                        handleAccept={handleEditNameAccept}/>

        <Card className={classes.root} variant="outlined">
            <CardHeader
                avatar={
                    <Avatar aria-label="user">
                        {props.document.uploader.name.charAt(0)}
                    </Avatar>
                }
                title={props.document.uploader.name}
            />

            <CardContent>
                <Typography variant="h5" component="h4">
                    Name: {docName}
                    {user.uid === props.document.uploader.uid &&
                    <IconButton aria-label="edit-doc-name" onClick={() => setOpenEditDocument(true)}>
                        <EditIcon/>
                    </IconButton>
                    }
                </Typography>
                <Typography variant="h6" component="h6">
                    Tag: { (tagId != null && tagId > 0) ?
                        props.tags.map((tag) => {
                        if (tag.id === tagId) {
                            return tag.name;
                        }})
                    :
                    "None"
                }
                </Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="signatures-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Jurisdiction</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {signatures != null && signatures.map((signature) => (
                                <TableRow key={signature.id}>
                                    <TableCell>
                                        {signature.signed ?
                                            <SignedIcon/> : <UnSignedIcon/>
                                        }
                                    </TableCell>
                                    <TableCell className={classes.nowrap}>
                                        {signature.signer.name}
                                    </TableCell>
                                    <TableCell className={classes.nowrap}>
                                        {moment(signature.date).format("DD-MM-yyyy HH:mm")}
                                    </TableCell>
                                    <TableCell>{signature.jurisdiction}</TableCell>
                                    <TableCell align="right">
                                        {!signature.signed &&
                                        <IconButton aria-label="remind-signer"
                                                    onClick={() => handleRemindSigner(signature.id)}>
                                            <ReminderIcon/>
                                        </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>

            <CardActions disableSpacing>
                <Tooltip title="Add signer" aria-label="add-signer">
                    <IconButton aria-label="add-signer" onClick={handleAddSigner}>
                        <AddSignerIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download Signer Page" aria-label="add-signer">
                    <IconButton aria-label="signing page" onClick={handleDownloadSigningPage}>
                        <SignedReportIcon/>
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>
    </>);
}

export default Document;
