import React, {useState, useEffect} from 'react';
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddEntityIcon from "@material-ui/icons/GroupAdd";
import { makeStyles } from '@material-ui/core/styles';
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";

import {
    TagControllerApi,
    TrustControllerApi,
    ClassificationsControllerApi
} from "./api";
import Entity from "./Entity";
import {checkError, errOptions} from "./util";
import EntityForm from "./EntityForm";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        margin: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function Documents(props) {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [trusts, setTrusts] = useState(null);
    const [tags, setTags] = useState(null);
    const [classifications, setClassifications] = useState(null);
    const [openEntityEditor, setOpenEntityEditor] = useState(false);
    const api = new TrustControllerApi(props.conf);

    useEffect(() => {
        api.listTrusts()
            .then( (t) => {
                setTrusts(t);
            })
            .catch( (e) => checkError(e,history, () =>
                enqueueSnackbar('Failed to retrieve entities: ' + e.statusText,
                    errOptions)));

        const tagsApi = new TagControllerApi(props.conf);
        tagsApi.listTags()
            .then( (r) => {
                setTags(r);
            })
            .catch( (e) => checkError(e,history, () =>
                enqueueSnackbar('Failed to retrieve tags: ' + e.statusText,
                    errOptions)));

        const classificationApi = new ClassificationsControllerApi(props.conf);
        classificationApi.listClassifications()
            .then( (r) => {
                setClassifications(r);
            })
            .catch( (e) => checkError(e,history, () =>
                enqueueSnackbar('Failed to retrieve classifications: ' + e.statusText,
                    errOptions)));
    },[]);

    const handleEditEntityClose = () => {
        setOpenEntityEditor(false);
    }

    const handleEditEntityAccept = (updatedEntity) => {
        setOpenEntityEditor(false);
        setTrusts([...trusts,updatedEntity]);
    }

    return (
        <Grid item xs={12} lg={8} className={classes.root}>
            <EntityForm conf={props.conf}
                        title="Add New Entity"
                        open={openEntityEditor}
                        handleClose={handleEditEntityClose}
                        onSubmit={handleEditEntityAccept}
                        entity={{}}
                        add={true}
                        entities={trusts}
                        classifications={classifications}
            />

            <Grid container direction="row" justify="flex-start" alignItems="center">
            <Button
                onClick={() => setOpenEntityEditor(true)}
                className={classes.button}
                variant="outlined"
                color="primary"
                startIcon={<AddEntityIcon />}>
                Add Entity
            </Button>
            </Grid>
            <List className={classes.root}>{trusts !== null && trusts.map((entity) =>
                <Entity conf={props.conf} key={entity.id} data-key={entity.id}
                        entity={entity} tags={tags}
                        classifications={classifications}
                /> )}
            </List>
        </Grid>
);
}

export default Documents;
