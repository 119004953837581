/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MainFolderRequest,
    MainFolderRequestFromJSON,
    MainFolderRequestToJSON,
    ProjectResource,
    ProjectResourceFromJSON,
    ProjectResourceToJSON,
} from '../models';

export interface CreateMainFolderRequest {
    request: MainFolderRequest;
}

export interface ListMainFoldersRequest {
    trustId: number;
}

export interface ShowMainFolderRequest {
    folderId: number;
}

export interface UpdateMainFolderRequest {
    folderId: number;
    request: MainFolderRequest;
}

/**
 * 
 */
export class ProjectControllerApi extends runtime.BaseAPI {

    /**
     * createMainFolder
     */
    async createMainFolderRaw(requestParameters: CreateMainFolderRequest): Promise<runtime.ApiResponse<ProjectResource>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling createMainFolder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/mainfolders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MainFolderRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResourceFromJSON(jsonValue));
    }

    /**
     * createMainFolder
     */
    async createMainFolder(requestParameters: CreateMainFolderRequest): Promise<ProjectResource> {
        const response = await this.createMainFolderRaw(requestParameters);
        return await response.value();
    }

    /**
     * listMainFolders
     */
    async listMainFoldersRaw(requestParameters: ListMainFoldersRequest): Promise<runtime.ApiResponse<Array<ProjectResource>>> {
        if (requestParameters.trustId === null || requestParameters.trustId === undefined) {
            throw new runtime.RequiredError('trustId','Required parameter requestParameters.trustId was null or undefined when calling listMainFolders.');
        }

        const queryParameters: any = {};

        if (requestParameters.trustId !== undefined) {
            queryParameters['trustId'] = requestParameters.trustId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/mainfolders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectResourceFromJSON));
    }

    /**
     * listMainFolders
     */
    async listMainFolders(requestParameters: ListMainFoldersRequest): Promise<Array<ProjectResource>> {
        const response = await this.listMainFoldersRaw(requestParameters);
        return await response.value();
    }

    /**
     * showMainFolder
     */
    async showMainFolderRaw(requestParameters: ShowMainFolderRequest): Promise<runtime.ApiResponse<ProjectResource>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling showMainFolder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/mainfolders/{folderId}`.replace(`{${"folderId"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResourceFromJSON(jsonValue));
    }

    /**
     * showMainFolder
     */
    async showMainFolder(requestParameters: ShowMainFolderRequest): Promise<ProjectResource> {
        const response = await this.showMainFolderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the main folder
     */
    async updateMainFolderRaw(requestParameters: UpdateMainFolderRequest): Promise<runtime.ApiResponse<ProjectResource>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling updateMainFolder.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling updateMainFolder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/mainfolders/{folderId}`.replace(`{${"folderId"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MainFolderRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResourceFromJSON(jsonValue));
    }

    /**
     * Update the main folder
     */
    async updateMainFolder(requestParameters: UpdateMainFolderRequest): Promise<ProjectResource> {
        const response = await this.updateMainFolderRaw(requestParameters);
        return await response.value();
    }

}
