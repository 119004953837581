/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganisationResource,
    OrganisationResourceFromJSON,
    OrganisationResourceFromJSONTyped,
    OrganisationResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginationResourceOrganisationResource
 */
export interface PaginationResourceOrganisationResource {
    /**
     * 
     * @type {Array<OrganisationResource>}
     * @memberof PaginationResourceOrganisationResource
     */
    data?: Array<OrganisationResource>;
    /**
     * 
     * @type {number}
     * @memberof PaginationResourceOrganisationResource
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResourceOrganisationResource
     */
    pageSize?: number;
}

export function PaginationResourceOrganisationResourceFromJSON(json: any): PaginationResourceOrganisationResource {
    return PaginationResourceOrganisationResourceFromJSONTyped(json, false);
}

export function PaginationResourceOrganisationResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationResourceOrganisationResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(OrganisationResourceFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
    };
}

export function PaginationResourceOrganisationResourceToJSON(value?: PaginationResourceOrganisationResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(OrganisationResourceToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
    };
}


