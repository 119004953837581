/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntityClassification
 */
export interface EntityClassification {
    /**
     * 
     * @type {Date}
     * @memberof EntityClassification
     */
    creationDateTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof EntityClassification
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityClassification
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityClassification
     */
    _new?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EntityClassification
     */
    updateDateTime?: Date;
}

export function EntityClassificationFromJSON(json: any): EntityClassification {
    return EntityClassificationFromJSONTyped(json, false);
}

export function EntityClassificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityClassification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationDateTime': !exists(json, 'creationDateTime') ? undefined : (new Date(json['creationDateTime'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        '_new': !exists(json, 'new') ? undefined : json['new'],
        'updateDateTime': !exists(json, 'updateDateTime') ? undefined : (new Date(json['updateDateTime'])),
    };
}

export function EntityClassificationToJSON(value?: EntityClassification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creationDateTime': value.creationDateTime === undefined ? undefined : (value.creationDateTime.toISOString()),
        'id': value.id,
        'name': value.name,
        'new': value._new,
        'updateDateTime': value.updateDateTime === undefined ? undefined : (value.updateDateTime.toISOString()),
    };
}


