/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentResource,
    DocumentResourceFromJSON,
    DocumentResourceFromJSONTyped,
    DocumentResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface SignatureSessionResource
 */
export interface SignatureSessionResource {
    /**
     * 
     * @type {boolean}
     * @memberof SignatureSessionResource
     */
    completed?: boolean;
    /**
     * 
     * @type {DocumentResource}
     * @memberof SignatureSessionResource
     */
    document?: DocumentResource;
    /**
     * 
     * @type {string}
     * @memberof SignatureSessionResource
     */
    token?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignatureSessionResource
     */
    validated?: boolean;
}

export function SignatureSessionResourceFromJSON(json: any): SignatureSessionResource {
    return SignatureSessionResourceFromJSONTyped(json, false);
}

export function SignatureSessionResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignatureSessionResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'document': !exists(json, 'document') ? undefined : DocumentResourceFromJSON(json['document']),
        'token': !exists(json, 'token') ? undefined : json['token'],
        'validated': !exists(json, 'validated') ? undefined : json['validated'],
    };
}

export function SignatureSessionResourceToJSON(value?: SignatureSessionResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed': value.completed,
        'document': DocumentResourceToJSON(value.document),
        'token': value.token,
        'validated': value.validated,
    };
}


