/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FIDO2ParametersResource,
    FIDO2ParametersResourceFromJSON,
    FIDO2ParametersResourceToJSON,
    UserRegisterCreateRequest,
    UserRegisterCreateRequestFromJSON,
    UserRegisterCreateRequestToJSON,
} from '../models';

export interface GetParametersBySignupTokenRequest {
    token: string;
}

export interface SignUpRequest {
    userRegisterCreateRequest: UserRegisterCreateRequest;
}

/**
 * 
 */
export class RegisterControllerApi extends runtime.BaseAPI {

    /**
     * Retrieve make credential parameters by looking up user from a signup token.
     */
    async getParametersBySignupTokenRaw(requestParameters: GetParametersBySignupTokenRequest): Promise<runtime.ApiResponse<FIDO2ParametersResource>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getParametersBySignupToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/register`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FIDO2ParametersResourceFromJSON(jsonValue));
    }

    /**
     * Retrieve make credential parameters by looking up user from a signup token.
     */
    async getParametersBySignupToken(requestParameters: GetParametersBySignupTokenRequest): Promise<FIDO2ParametersResource> {
        const response = await this.getParametersBySignupTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Register new user with FIDO response
     */
    async signUpRaw(requestParameters: SignUpRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userRegisterCreateRequest === null || requestParameters.userRegisterCreateRequest === undefined) {
            throw new runtime.RequiredError('userRegisterCreateRequest','Required parameter requestParameters.userRegisterCreateRequest was null or undefined when calling signUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegisterCreateRequestToJSON(requestParameters.userRegisterCreateRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Register new user with FIDO response
     */
    async signUp(requestParameters: SignUpRequest): Promise<void> {
        await this.signUpRaw(requestParameters);
    }

}
