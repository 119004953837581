import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
    matchPath, useHistory
} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderIcon from '@material-ui/icons/Folder';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import ProfileIcon from '@material-ui/icons/Face';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ReportsIcon from '@material-ui/icons/Assessment';
import Organizations from "./Organizations";
import Users from "./Users";
import Documents from "./Documents";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import Reports from "./Reports";
import {
    Configuration, UserControllerApi,
} from './api';
import AppContext from './AppContext';
import {checkError, errOptions} from "./util";
import {useSnackbar} from "notistack";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function Main(props) {
    const classes = useStyles();
    const { basePath } = useContext(AppContext);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const match = useRouteMatch();
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [view, setView] = useState(null);
    const [users, setUsers] = useState(null);
    const [user, setUser] = useState(null);
    const contextValue = { users, setUsers, user, setUser };

    const params = {basePath: basePath, apiKey: 'Bearer ' + props.login.token};
    const conf = new Configuration(params);

    useEffect(
        function getUser() {
            const api = new UserControllerApi(conf);
            api.getUser({ uid: props.login.uid})
                .then(u => {
                    setUser(u);
                })
                .catch( (e) => {
                    checkError(e,history, () =>
                        enqueueSnackbar('Failed to request user profile: ' + e.statusText,
                            errOptions))
                });
        },
        [view],
    );

    useEffect(
        function getUsers() {
            const api = new UserControllerApi(conf);
            api.listUsers({})
                .then(userList => {
                    setUsers(userList.data);
                })
                .catch( (e) => {
                    checkError(e,history, () =>
                        enqueueSnackbar('Failed to request user list: ' + e.statusText,
                            errOptions))
                });
        },
        [view],
    );

    useEffect(() => {
        if (location != null) {
            if (matchPath(location.pathname, {path: match.path + '/organizations'}) != null) {
                setView('Organizations');
            } else if (matchPath(location.pathname, {path: match.path + '/documents'}) != null) {
                setView('Documents');
            } else if (matchPath(location.pathname, {path: match.path + '/users'}) != null) {
                setView('Users');
            } else if (matchPath(location.pathname, {path: match.path + '/profile'}) != null) {
                setView('Profile');
            } else if (matchPath(location.pathname, {path: match.path + '/reports'}) != null) {
                setView('Reports');
            } else {
                setView('Dashboard');
            }
        }
    },[location, match]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
            <div className={classes.root}>
                <AppContext.Provider value={contextValue}>
                <AppBar position="fixed" className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {props.login.name} - {view}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <Link to={`${match.url}`}>
                        <ListItem button key="dashboard">
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        </Link>
                        <Link to={`${match.url}/documents`}>
                            <ListItem button key="documents">
                                <ListItemIcon><FolderIcon /></ListItemIcon>
                                <ListItemText primary="Documents" />
                            </ListItem>
                        </Link>
                        <Link to={`${match.url}/organizations`}>
                            <ListItem button key="organizations">
                                <ListItemIcon><BusinessIcon /></ListItemIcon>
                                <ListItemText primary="Organizations" />
                            </ListItem>
                        </Link>
                        <Link to={`${match.url}/users`}>
                            <ListItem button key="users">
                                <ListItemIcon><PeopleIcon /></ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                        </Link>
                        <Link to={`${match.url}/profile`}>
                            <ListItem button key="profile">
                                <ListItemIcon><ProfileIcon /></ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItem>
                        </Link>
                        <Link to={`${match.url}/reports`}>
                            <ListItem button key="reports">
                                <ListItemIcon><ReportsIcon /></ListItemIcon>
                                <ListItemText primary="Reports" />
                            </ListItem>
                        </Link>
                    </List>
                    <Divider />
                    <List>
                        <Link to='/logout'>
                            <ListItem button key="logout">
                                <ListItemIcon><LogoutIcon /></ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItem>
                        </Link>
                    </List>
                </Drawer>
                    {user != null &&
                    <main className={classes.content}>
                        <div className={classes.toolbar}/>
                        <Grid container justify="center">
                            <Switch>
                                <Route path={`${match.path}/organizations`}>
                                    <Organizations conf={conf}/>
                                </Route>
                                <Route path={`${match.path}/documents`}>
                                    <Documents conf={conf}/>
                                </Route>
                                <Route path={`${match.path}/users`}>
                                    <Users conf={conf}/>
                                </Route>
                                <Route path={`${match.path}/profile`}>
                                    <Profile conf={conf}/>
                                </Route>
                                <Route path={`${match.path}/reports`}>
                                    <Reports conf={conf}/>
                                </Route>
                                <Route path={`${match.path}`}>
                                    <Dashboard conf={conf}/>
                                </Route>
                            </Switch>
                        </Grid>
                    </main>
                    }
                </AppContext.Provider>
            </div>
    );
}

export default Main;
