/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface DownloadDocumentRequest {
    signatureRequestId: number;
}

/**
 * 
 */
export class SignatureRequestDocumentControllerApi extends runtime.BaseAPI {

    /**
     * downloadDocument
     */
    async downloadDocumentRaw(requestParameters: DownloadDocumentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.signatureRequestId === null || requestParameters.signatureRequestId === undefined) {
            throw new runtime.RequiredError('signatureRequestId','Required parameter requestParameters.signatureRequestId was null or undefined when calling downloadDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-request-documents/{signatureRequestId}`.replace(`{${"signatureRequestId"}}`, encodeURIComponent(String(requestParameters.signatureRequestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * downloadDocument
     */
    async downloadDocument(requestParameters: DownloadDocumentRequest): Promise<Blob> {
        const response = await this.downloadDocumentRaw(requestParameters);
        return await response.value();
    }

}
