/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentResource,
    DocumentResourceFromJSON,
    DocumentResourceFromJSONTyped,
    DocumentResourceToJSON,
    UserResource,
    UserResourceFromJSON,
    UserResourceFromJSONTyped,
    UserResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface SignatureRequestResource
 */
export interface SignatureRequestResource {
    /**
     * 
     * @type {string}
     * @memberof SignatureRequestResource
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SignatureRequestResource
     */
    date?: string;
    /**
     * 
     * @type {DocumentResource}
     * @memberof SignatureRequestResource
     */
    document?: DocumentResource;
    /**
     * 
     * @type {boolean}
     * @memberof SignatureRequestResource
     */
    downloaded?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SignatureRequestResource
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SignatureRequestResource
     */
    jurisdiction?: string;
    /**
     * 
     * @type {number}
     * @memberof SignatureRequestResource
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof SignatureRequestResource
     */
    longitude?: number;
    /**
     * 
     * @type {UserResource}
     * @memberof SignatureRequestResource
     */
    requester?: UserResource;
    /**
     * 
     * @type {boolean}
     * @memberof SignatureRequestResource
     */
    signed?: boolean;
    /**
     * 
     * @type {UserResource}
     * @memberof SignatureRequestResource
     */
    signer?: UserResource;
    /**
     * 
     * @type {boolean}
     * @memberof SignatureRequestResource
     */
    verified?: boolean;
}

export function SignatureRequestResourceFromJSON(json: any): SignatureRequestResource {
    return SignatureRequestResourceFromJSONTyped(json, false);
}

export function SignatureRequestResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignatureRequestResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : json['createdDate'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'document': !exists(json, 'document') ? undefined : DocumentResourceFromJSON(json['document']),
        'downloaded': !exists(json, 'downloaded') ? undefined : json['downloaded'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'jurisdiction': !exists(json, 'jurisdiction') ? undefined : json['jurisdiction'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'requester': !exists(json, 'requester') ? undefined : UserResourceFromJSON(json['requester']),
        'signed': !exists(json, 'signed') ? undefined : json['signed'],
        'signer': !exists(json, 'signer') ? undefined : UserResourceFromJSON(json['signer']),
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
    };
}

export function SignatureRequestResourceToJSON(value?: SignatureRequestResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate,
        'date': value.date,
        'document': DocumentResourceToJSON(value.document),
        'downloaded': value.downloaded,
        'id': value.id,
        'jurisdiction': value.jurisdiction,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'requester': UserResourceToJSON(value.requester),
        'signed': value.signed,
        'signer': UserResourceToJSON(value.signer),
        'verified': value.verified,
    };
}


