/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginationResourceUserResource,
    PaginationResourceUserResourceFromJSON,
    PaginationResourceUserResourceToJSON,
    UserCreateRequest,
    UserCreateRequestFromJSON,
    UserCreateRequestToJSON,
    UserResource,
    UserResourceFromJSON,
    UserResourceToJSON,
    UserUpdateRequest,
    UserUpdateRequestFromJSON,
    UserUpdateRequestToJSON,
} from '../models';

export interface CreateUserRequest {
    resource: UserCreateRequest;
}

export interface DeleteUserRequest {
    uid: string;
}

export interface GetUserRequest {
    uid: string;
}

export interface ListUsersRequest {
    page?: number;
    sort?: string;
}

export interface UpdateUserRequest {
    uid: string;
    resource: UserUpdateRequest;
}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI {

    /**
     * Create a new user
     */
    async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<UserResource>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateRequestToJSON(requestParameters.resource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResourceFromJSON(jsonValue));
    }

    /**
     * Create a new user
     */
    async createUser(requestParameters: CreateUserRequest): Promise<UserResource> {
        const response = await this.createUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters.uid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a user
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }

    /**
     * Mark that the user has downloaded the desktop application
     */
    async downloadedDesktopApplicationRaw(): Promise<runtime.ApiResponse<UserResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/downloaded-desktop-application`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResourceFromJSON(jsonValue));
    }

    /**
     * Mark that the user has downloaded the desktop application
     */
    async downloadedDesktopApplication(): Promise<UserResource> {
        const response = await this.downloadedDesktopApplicationRaw();
        return await response.value();
    }

    /**
     * Find a user by uid
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<UserResource>> {
        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters.uid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResourceFromJSON(jsonValue));
    }

    /**
     * Find a user by uid
     */
    async getUser(requestParameters: GetUserRequest): Promise<UserResource> {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a list of users
     */
    async listUsersRaw(requestParameters: ListUsersRequest): Promise<runtime.ApiResponse<PaginationResourceUserResource>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResourceUserResourceFromJSON(jsonValue));
    }

    /**
     * Get a list of users
     */
    async listUsers(requestParameters: ListUsersRequest): Promise<PaginationResourceUserResource> {
        const response = await this.listUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Mark that the user has read the instructions
     */
    async readInstructionsRaw(): Promise<runtime.ApiResponse<UserResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/read-instructions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResourceFromJSON(jsonValue));
    }

    /**
     * Mark that the user has read the instructions
     */
    async readInstructions(): Promise<UserResource> {
        const response = await this.readInstructionsRaw();
        return await response.value();
    }

    /**
     * Update a new user
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<UserResource>> {
        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling updateUser.');
        }

        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters.uid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateRequestToJSON(requestParameters.resource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResourceFromJSON(jsonValue));
    }

    /**
     * Update a new user
     */
    async updateUser(requestParameters: UpdateUserRequest): Promise<UserResource> {
        const response = await this.updateUserRaw(requestParameters);
        return await response.value();
    }

}
