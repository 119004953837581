/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrganisationCreateRequest,
    OrganisationCreateRequestFromJSON,
    OrganisationCreateRequestToJSON,
    OrganisationResource,
    OrganisationResourceFromJSON,
    OrganisationResourceToJSON,
    OrganisationUpdateRequest,
    OrganisationUpdateRequestFromJSON,
    OrganisationUpdateRequestToJSON,
    PaginationResourceOrganisationResource,
    PaginationResourceOrganisationResourceFromJSON,
    PaginationResourceOrganisationResourceToJSON,
} from '../models';

export interface CreateOrganisationRequest {
    request: OrganisationCreateRequest;
}

export interface DeleteOrganisationRequest {
    organisationId: number;
}

export interface ListOrganisationsRequest {
    page?: number;
    sort?: string;
}

export interface ShowOrganisationRequest {
    organisationId: number;
}

export interface UpdateOrganisationRequest {
    organisationId: number;
    request: OrganisationUpdateRequest;
}

/**
 * 
 */
export class OrganisationControllerApi extends runtime.BaseAPI {

    /**
     * createOrganisation
     */
    async createOrganisationRaw(requestParameters: CreateOrganisationRequest): Promise<runtime.ApiResponse<OrganisationResource>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling createOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/organisations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganisationCreateRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationResourceFromJSON(jsonValue));
    }

    /**
     * createOrganisation
     */
    async createOrganisation(requestParameters: CreateOrganisationRequest): Promise<OrganisationResource> {
        const response = await this.createOrganisationRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleteOrganisation
     */
    async deleteOrganisationRaw(requestParameters: DeleteOrganisationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling deleteOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/organisations/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteOrganisation
     */
    async deleteOrganisation(requestParameters: DeleteOrganisationRequest): Promise<void> {
        await this.deleteOrganisationRaw(requestParameters);
    }

    /**
     * listOrganisations
     */
    async listOrganisationsRaw(requestParameters: ListOrganisationsRequest): Promise<runtime.ApiResponse<PaginationResourceOrganisationResource>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/organisations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResourceOrganisationResourceFromJSON(jsonValue));
    }

    /**
     * listOrganisations
     */
    async listOrganisations(requestParameters: ListOrganisationsRequest): Promise<PaginationResourceOrganisationResource> {
        const response = await this.listOrganisationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * show
     */
    async showOrganisationRaw(requestParameters: ShowOrganisationRequest): Promise<runtime.ApiResponse<OrganisationResource>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling showOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/organisations/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationResourceFromJSON(jsonValue));
    }

    /**
     * show
     */
    async showOrganisation(requestParameters: ShowOrganisationRequest): Promise<OrganisationResource> {
        const response = await this.showOrganisationRaw(requestParameters);
        return await response.value();
    }

    /**
     * update
     */
    async updateOrganisationRaw(requestParameters: UpdateOrganisationRequest): Promise<runtime.ApiResponse<OrganisationResource>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling updateOrganisation.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling updateOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/organisations/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganisationUpdateRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationResourceFromJSON(jsonValue));
    }

    /**
     * update
     */
    async updateOrganisation(requestParameters: UpdateOrganisationRequest): Promise<OrganisationResource> {
        const response = await this.updateOrganisationRaw(requestParameters);
        return await response.value();
    }

}
