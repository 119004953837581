import React, {useState, useEffect} from 'react';
import {useSnackbar} from "notistack";
import {MobileDeviceControllerApi, MobileDeviceOtpControllerApi} from "./api";
import {checkError, errOptions} from "./util";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    warningButton: {
        backgroundColor: theme.palette.warning.main,
    },
}));

function Devices(props) {
    const classes = useStyles();
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar();
    const [updateAttempt, setUpdateAttempt] = useState(false);
    const [devices, setDevices] = useState(null);
    const [otp, setOTP] = useState(null);
    const api = new MobileDeviceControllerApi(props.conf);

    useEffect(() => {
        if (!updateAttempt) {
            setUpdateAttempt(true);
            const otpApi = new MobileDeviceOtpControllerApi(props.conf);
            api.listMobileDevices()
                .then((d) => {
                    if (d.length === 0) {
                        otpApi.getDeviceOTP()
                            .then((otp) => {
                                setOTP(otp);
                            })
                            .catch((ex) => enqueueSnackbar('Failed to get device OTP: ' + ex.statusText,
                                errOptions));
                    } else {
                        setDevices(d);
                    }
                })
                .catch((e) => checkError(e, history, () =>
                    enqueueSnackbar('Failed to get device list: ' + e.statusText,
                        errOptions))
                );
        }
    });

    const handleDeregister = () => {
        api.deleteMobileDevice( { userId: props.user.uid})
            .then( () => {
                setDevices(null);
                setUpdateAttempt(false);
            })
            .catch( (e) => checkError(e,history, () =>
                enqueueSnackbar('Failed to unregister device: ' + e.statusText,
                    errOptions))
            );
    };

    return (
        <>
        {devices !== null ? <>
            <Typography>A mobile device has already been registered.</Typography>
                <Button
                    variant="contained"
                    className={`${classes.warningButton} ${classes.button}`}
                    onClick={handleDeregister}
                    startIcon={<DeleteIcon/>}>
                    Unregister device
                </Button>
            </> :
        <Typography>
            No registered mobile devices found.<br/>Register a mobile device using OTP: <b>{otp}</b>
        </Typography>
        }
        </>
    );
}

export default Devices;
