import React, {useState, useEffect} from 'react';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import {OrganisationControllerApi} from './api';
import { Organization, OrgForm } from "./Organization";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
}));

function Organizations(props) {
    const classes = useStyles();
    const [orgs, setOrgs] = useState(null);
    const match = useRouteMatch();
    const history = useHistory();
    const api = new OrganisationControllerApi(props.conf);

    useEffect(() => {
        if (orgs == null) {
            api.listOrganisations({})
                .then(list => {
                    setOrgs(list.data);
                })
                .catch(e => {
                    if (e.status === 403) {
                        history.push("/login");
                    } else {
                        console.log(e);
                    }
                });
        }
    });

    function findOrgIdx(id) {
        for (let i = 0; i < orgs.length; i++) {
            if (orgs[i].id === id)
                return i;
        }
    }

    const updateOrg = org => {
        api.updateOrganisation({ organisationId: org.id, request: org })
            .then( (r) => {
                orgs[findOrgIdx(org.id)] = org;
                setOrgs(orgs);
                history.push(match.path);
            })
            .catch( (e) => {
                console.log(e);
            });
    };

    const handleDeleteOrg = id => {
        api.deleteOrganisation({organisationId: id})
            .then( (r) => {
                let idx = findOrgIdx(parseInt(id));
                orgs.splice(idx,1);
                setOrgs(orgs);
                history.push(match.path);
            })
            .catch( (e) => {
                console.log(e);
            });
    }

    const handleAddOrg = org => {
        api.createOrganisation({ request: org })
            .then( (r) => {
                orgs.push(org);
                setOrgs(orgs);
                history.push(match.path);
            })
            .catch( (e) => {
                console.log(e);
            });
    };

    return (
        <>
            <Grid item xs={12} lg={6} className={classes.root}>
                <Switch>
                    <Route path={`${match.path}/add`} render={routeProps => {
                        let org= { name: "",
                            registrationNumber: "",
                            country: "",
                            address1: "",
                            address2: "",
                            city: "",
                            locality: "",
                            postalCode: ""
                        };
                        return (<OrgForm org={org} onSubmit={handleAddOrg}/>
                        );}
                    }/>
                    <Route path={`${match.path}/edit/:id`} render={routeProps => {
                        if (orgs == null) {
                            history.push(match.path);
                            return ("");
                        } else {
                            let org = orgs[findOrgIdx(parseInt(routeProps.match.params.id))];
                            return (<OrgForm org={org} onSubmit={updateOrg} onDelete={handleDeleteOrg}/>);
                        }
                        }
                    }/>
                    <Route path={`${match.path}/`}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            component={Link} to={`${match.path}/add`}
                            startIcon={<AddIcon />}>
                            Create Organization
                        </Button>
                        <List>{orgs !== null && orgs.map((org) =>
                            <Organization key={org.id} org={org}/>)}
                        </List>
                    </Route>
                </Switch>
            </Grid>
        </>
    );
}

export default Organizations;
