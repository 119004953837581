/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FIDO2DeviceRegisterRequest,
    FIDO2DeviceRegisterRequestFromJSON,
    FIDO2DeviceRegisterRequestToJSON,
    FIDO2ParametersResource,
    FIDO2ParametersResourceFromJSON,
    FIDO2ParametersResourceToJSON,
    FIDODeviceResource,
    FIDODeviceResourceFromJSON,
    FIDODeviceResourceToJSON,
} from '../models';

export interface RegisterFIDODeviceRequest {
    fido2DeviceRegisterRequest: FIDO2DeviceRegisterRequest;
}

export interface RemoveSecurityKeyRequest {
    id: number;
}

/**
 * 
 */
export class FidoControllerApi extends runtime.BaseAPI {

    /**
     * Retrieve params used for FIDO registration
     */
    async getFIDOParametersRaw(): Promise<runtime.ApiResponse<FIDO2ParametersResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/fido/params`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FIDO2ParametersResourceFromJSON(jsonValue));
    }

    /**
     * Retrieve params used for FIDO registration
     */
    async getFIDOParameters(): Promise<FIDO2ParametersResource> {
        const response = await this.getFIDOParametersRaw();
        return await response.value();
    }

    /**
     * List registered FIDO devices
     */
    async listFIDODevicesRaw(): Promise<runtime.ApiResponse<Array<FIDODeviceResource>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/fido/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FIDODeviceResourceFromJSON));
    }

    /**
     * List registered FIDO devices
     */
    async listFIDODevices(): Promise<Array<FIDODeviceResource>> {
        const response = await this.listFIDODevicesRaw();
        return await response.value();
    }

    /**
     * Register FIDO device
     */
    async registerFIDODeviceRaw(requestParameters: RegisterFIDODeviceRequest): Promise<runtime.ApiResponse<FIDODeviceResource>> {
        if (requestParameters.fido2DeviceRegisterRequest === null || requestParameters.fido2DeviceRegisterRequest === undefined) {
            throw new runtime.RequiredError('fido2DeviceRegisterRequest','Required parameter requestParameters.fido2DeviceRegisterRequest was null or undefined when calling registerFIDODevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/fido/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FIDO2DeviceRegisterRequestToJSON(requestParameters.fido2DeviceRegisterRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FIDODeviceResourceFromJSON(jsonValue));
    }

    /**
     * Register FIDO device
     */
    async registerFIDODevice(requestParameters: RegisterFIDODeviceRequest): Promise<FIDODeviceResource> {
        const response = await this.registerFIDODeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Remove registered security key
     */
    async removeSecurityKeyRaw(requestParameters: RemoveSecurityKeyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeSecurityKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/fido/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove registered security key
     */
    async removeSecurityKey(requestParameters: RemoveSecurityKeyRequest): Promise<void> {
        await this.removeSecurityKeyRaw(requestParameters);
    }

}
