/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FIDO2ParametersResource
 */
export interface FIDO2ParametersResource {
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    attestation?: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    authenticator?: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    challenge?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FIDO2ParametersResource
     */
    credentials?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    origin?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FIDO2ParametersResource
     */
    rk?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    rpId?: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    rpName?: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    userDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2ParametersResource
     */
    userName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FIDO2ParametersResource
     */
    uv?: boolean;
}

export function FIDO2ParametersResourceFromJSON(json: any): FIDO2ParametersResource {
    return FIDO2ParametersResourceFromJSONTyped(json, false);
}

export function FIDO2ParametersResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FIDO2ParametersResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attestation': !exists(json, 'attestation') ? undefined : json['attestation'],
        'authenticator': !exists(json, 'authenticator') ? undefined : json['authenticator'],
        'challenge': !exists(json, 'challenge') ? undefined : json['challenge'],
        'credentials': !exists(json, 'credentials') ? undefined : json['credentials'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'rk': !exists(json, 'rk') ? undefined : json['rk'],
        'rpId': !exists(json, 'rpId') ? undefined : json['rpId'],
        'rpName': !exists(json, 'rpName') ? undefined : json['rpName'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'userDisplayName': !exists(json, 'userDisplayName') ? undefined : json['userDisplayName'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'uv': !exists(json, 'uv') ? undefined : json['uv'],
    };
}

export function FIDO2ParametersResourceToJSON(value?: FIDO2ParametersResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attestation': value.attestation,
        'authenticator': value.authenticator,
        'challenge': value.challenge,
        'credentials': value.credentials,
        'origin': value.origin,
        'rk': value.rk,
        'rpId': value.rpId,
        'rpName': value.rpName,
        'token': value.token,
        'userDisplayName': value.userDisplayName,
        'userId': value.userId,
        'userName': value.userName,
        'uv': value.uv,
    };
}


