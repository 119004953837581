/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportExportRowItemResource,
    ReportExportRowItemResourceFromJSON,
    ReportExportRowItemResourceToJSON,
} from '../models';

export interface CreateReportRequest {
    endDate?: Date;
    queryTerm?: string;
    startDate?: Date;
}

/**
 * 
 */
export class ReportExportControllerApi extends runtime.BaseAPI {

    /**
     * createReport
     */
    async createReportRaw(requestParameters: CreateReportRequest): Promise<runtime.ApiResponse<Array<ReportExportRowItemResource>>> {
        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.queryTerm !== undefined) {
            queryParameters['queryTerm'] = requestParameters.queryTerm;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/report-exports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportExportRowItemResourceFromJSON));
    }

    /**
     * createReport
     */
    async createReport(requestParameters: CreateReportRequest): Promise<Array<ReportExportRowItemResource>> {
        const response = await this.createReportRaw(requestParameters);
        return await response.value();
    }

}
