import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {DocumentControllerApi, FolderControllerApi} from "./api";
import {checkError, errOptions} from "./util";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import AddDocumentIcon from "@material-ui/icons/LibraryAdd";
import FolderIcon from "@material-ui/icons/FolderOpen";
import UploadIcon from "@material-ui/icons/CloudUpload";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InputLabel from '@material-ui/core/InputLabel';
import DocumentsIcon from "@material-ui/icons/LibraryBooks";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import Document from "./Document";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import OKCancelDialog from "./OKCancelDialog";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function Folder(props) {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openFolderEditor, setOpenFolderEditor] = useState(false);
    const [folderName, setFolderName] = useState(props.folder.name);
    const [documents, setDocuments] = useState(null);
    const [file, setFile] = useState(null);
    const [tagId, setTagId] = useState(props.tags[0].id);
    const api = new DocumentControllerApi(props.conf);

    useEffect(() => {
        api.listDocuments( {folder: props.folder.id})
            .then( (t) => {
                setDocuments(t);
            })
            .catch( (e) => checkError(e,history, () =>
                enqueueSnackbar('Failed to list documents: ' + e.statusText,
                    errOptions)));
    },[]);

    const handleClick = () => {
        setOpen(!open);
    };

    const onUpload = () => {
        api.createDocument({
            file: file,
            folderId: props.folder.id,
            tagId: tagId
        })
            .then( (d) => {
                setDocuments([...documents,d]);
                setFile(null);
                enqueueSnackbar('Uploaded file.',{autoHideDuration: 3000, variant: 'success'});

            })
            .catch( (e) => checkError(e,history, () =>
                enqueueSnackbar('Failed to upload file: ' + e.statusText,
                    errOptions)));
    };

    const onFileChange = event => {
        setFile(event.target.files[0]);
    };

    const handleUpdateFolder = () => {
        setOpenFolderEditor(false);
        if (props.folders.find( (f) => { return f.name === folderName})) {
            enqueueSnackbar('Folder '+ folderName+ ' already exists', errOptions);
            setFolderName(props.folder.name);
            return;
        }

        const api = new FolderControllerApi(props.conf);
        api.updateFolder({
            folderId: props.folder.id,
            request: {
                name: folderName,
                mainFolderId: props.folder.project.id
            }
        })
            .then((r) => {

            })
            .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to update folder: ' + e.statusText,
                    errOptions)));

    }

    const folderNameEditFragment = () => {
        return (<>
            <TextField
                variant="outlined"
                id={props.folder.id +"name"}
                name="name"
                label="Folder Name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
            />
        </>);
    };

    return (<>
            <OKCancelDialog title="Change folder name"
                            description=""
                            render={folderNameEditFragment}
                            open={openFolderEditor}
                            handleClose={() => setOpenFolderEditor(false)}
                            handleAccept={handleUpdateFolder}/>

            <ListItem key={props.folder.id}>
                <ListItemIcon><FolderIcon/></ListItemIcon>
                <ListItemText primary={folderName} />
                <IconButton aria-label="edit-folder" onClick={() => {
                    setOpenFolderEditor(true);
                }}>
                    <EditIcon/>
                </IconButton>
            </ListItem>
            <ListItem key={props.folder.id+'add'}>
                <label htmlFor={props.folder.id+'btn-upload'}>
                    <input
                        id={props.folder.id+'btn-upload'}
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={onFileChange} />
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        component="span"
                        startIcon={<AddDocumentIcon />}>
                        Add Document
                    </Button>
                </label>
                {file !== null &&
                <>
                    <ListItemText secondary={"Document: " + file.name}/>
                    <FormControl>
                    <InputLabel htmlFor={props.folder.id+'document-tag'}>Document Tag</InputLabel>
                    <Select
                        native
                        inputProps={{
                            name: 'tag',
                            id: props.folder.id+'document-tag',
                        }}
                        value={tagId}
                        onChange={(e) => setTagId(e.target.value)}
                    >
                        {props.tags.map( (tag) =>
                            <option key={tag.id} value={tag.id}>{tag.name}</option>)
                        }
                    </Select>
                    </FormControl>
                    <Button
                        variant="outlined"
                        onClick={onUpload}
                        color="primary"
                        className={classes.button}
                        startIcon={<UploadIcon/>}>
                        Upload
                    </Button>
                </>
                }
            </ListItem>
            <ListItem key={props.folder.id+'bt'} button onClick={handleClick}>
                <ListItemIcon><DocumentsIcon/></ListItemIcon>
                <ListItemText primary="Documents" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List key={props.folder.id} component="div" disablePadding className={classes.nested}>
                    {documents !== null &&
                    documents.map((document) =>
                        <ListItem key={document.uid}>
                            <Document key={document.uid+'bt'} conf={props.conf} document={document}
                            tags={props.tags}/>
                        </ListItem>
                    )
                    }
                </List>
            </Collapse>
        </>
    );
}

export default Folder;
