/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MobileDeviceCreateRequest,
    MobileDeviceCreateRequestFromJSON,
    MobileDeviceCreateRequestToJSON,
    MobileDeviceResource,
    MobileDeviceResourceFromJSON,
    MobileDeviceResourceToJSON,
    MobileDeviceUpdateRequest,
    MobileDeviceUpdateRequestFromJSON,
    MobileDeviceUpdateRequestToJSON,
} from '../models';

export interface CreateMobileDeviceRequest {
    mobileDeviceCreateRequest: MobileDeviceCreateRequest;
}

export interface DeleteMobileDeviceRequest {
    userId: string;
}

export interface UpdateMobileDeviceRequest {
    resource: MobileDeviceUpdateRequest;
}

/**
 * 
 */
export class MobileDeviceControllerApi extends runtime.BaseAPI {

    /**
     * Create a new mobile device and attach it to a user using the OTP code
     */
    async createMobileDeviceRaw(requestParameters: CreateMobileDeviceRequest): Promise<runtime.ApiResponse<MobileDeviceResource>> {
        if (requestParameters.mobileDeviceCreateRequest === null || requestParameters.mobileDeviceCreateRequest === undefined) {
            throw new runtime.RequiredError('mobileDeviceCreateRequest','Required parameter requestParameters.mobileDeviceCreateRequest was null or undefined when calling createMobileDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/mobile-devices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MobileDeviceCreateRequestToJSON(requestParameters.mobileDeviceCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileDeviceResourceFromJSON(jsonValue));
    }

    /**
     * Create a new mobile device and attach it to a user using the OTP code
     */
    async createMobileDevice(requestParameters: CreateMobileDeviceRequest): Promise<MobileDeviceResource> {
        const response = await this.createMobileDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleteMobileDevice
     */
    async deleteMobileDeviceRaw(requestParameters: DeleteMobileDeviceRequest): Promise<runtime.ApiResponse<MobileDeviceResource>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteMobileDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/mobile-devices/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileDeviceResourceFromJSON(jsonValue));
    }

    /**
     * deleteMobileDevice
     */
    async deleteMobileDevice(requestParameters: DeleteMobileDeviceRequest): Promise<MobileDeviceResource> {
        const response = await this.deleteMobileDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a list of mobile devices attached to the authenticated user
     */
    async listMobileDevicesRaw(): Promise<runtime.ApiResponse<Array<MobileDeviceResource>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/mobile-devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MobileDeviceResourceFromJSON));
    }

    /**
     * Get a list of mobile devices attached to the authenticated user
     */
    async listMobileDevices(): Promise<Array<MobileDeviceResource>> {
        const response = await this.listMobileDevicesRaw();
        return await response.value();
    }

    /**
     * Set the FCM token of the mobile device for sending for notifications
     */
    async updateMobileDeviceRaw(requestParameters: UpdateMobileDeviceRequest): Promise<runtime.ApiResponse<MobileDeviceResource>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling updateMobileDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/mobile-devices`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MobileDeviceUpdateRequestToJSON(requestParameters.resource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileDeviceResourceFromJSON(jsonValue));
    }

    /**
     * Set the FCM token of the mobile device for sending for notifications
     */
    async updateMobileDevice(requestParameters: UpdateMobileDeviceRequest): Promise<MobileDeviceResource> {
        const response = await this.updateMobileDeviceRaw(requestParameters);
        return await response.value();
    }

}
