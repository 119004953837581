/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileDeviceCreateRequest
 */
export interface MobileDeviceCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceCreateRequest
     */
    fcmToken: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceCreateRequest
     */
    keyType: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceCreateRequest
     */
    otp: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceCreateRequest
     */
    publicKey: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceCreateRequest
     */
    signedOTP: string;
}

export function MobileDeviceCreateRequestFromJSON(json: any): MobileDeviceCreateRequest {
    return MobileDeviceCreateRequestFromJSONTyped(json, false);
}

export function MobileDeviceCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileDeviceCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fcmToken': json['fcmToken'],
        'keyType': json['keyType'],
        'otp': json['otp'],
        'publicKey': json['publicKey'],
        'signedOTP': json['signedOTP'],
    };
}

export function MobileDeviceCreateRequestToJSON(value?: MobileDeviceCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fcmToken': value.fcmToken,
        'keyType': value.keyType,
        'otp': value.otp,
        'publicKey': value.publicKey,
        'signedOTP': value.signedOTP,
    };
}


