/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserRegisterCreateRequest
 */
export interface UserRegisterCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRegisterCreateRequest
     */
    attestationObject: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterCreateRequest
     */
    challengeToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterCreateRequest
     */
    clientDataJSON: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterCreateRequest
     */
    credentialId: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterCreateRequest
     */
    keyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterCreateRequest
     */
    token?: string;
}

export function UserRegisterCreateRequestFromJSON(json: any): UserRegisterCreateRequest {
    return UserRegisterCreateRequestFromJSONTyped(json, false);
}

export function UserRegisterCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegisterCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attestationObject': json['attestationObject'],
        'challengeToken': !exists(json, 'challengeToken') ? undefined : json['challengeToken'],
        'clientDataJSON': json['clientDataJSON'],
        'credentialId': json['credentialId'],
        'keyName': !exists(json, 'keyName') ? undefined : json['keyName'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function UserRegisterCreateRequestToJSON(value?: UserRegisterCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attestationObject': value.attestationObject,
        'challengeToken': value.challengeToken,
        'clientDataJSON': value.clientDataJSON,
        'credentialId': value.credentialId,
        'keyName': value.keyName,
        'token': value.token,
    };
}


