/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionReadyForSigningResource
 */
export interface SessionReadyForSigningResource {
    /**
     * 
     * @type {number}
     * @memberof SessionReadyForSigningResource
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionReadyForSigningResource
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionReadyForSigningResource
     */
    sessionId?: string;
}

export function SessionReadyForSigningResourceFromJSON(json: any): SessionReadyForSigningResource {
    return SessionReadyForSigningResourceFromJSONTyped(json, false);
}

export function SessionReadyForSigningResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionReadyForSigningResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
    };
}

export function SessionReadyForSigningResourceToJSON(value?: SessionReadyForSigningResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'sessionId': value.sessionId,
    };
}


