import React, {useContext, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Configuration, LoginControllerApi} from './api';
import base64url from './base64';
import AppContext from "./AppContext";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignIn(props) {
    const { basePath } = useContext(AppContext);
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");

    const onClick = () => {
        if (!navigator.credentials) {
            setError(true);
            setHelperText("This browser does not support security key log-ins (WebAuthn).");
            return;
        }

        if (username.length <= 4) {
            setError(true);
            setHelperText("Invalid user name.");
            return;
        }

        const params = {basePath: basePath};
        const conf = new Configuration(params);
        const api = new LoginControllerApi(conf);
        api.getAssertionParameters({email: username}).then((p) => {
            const pubKey = _getAssertion(p);
            navigator.credentials.get({ 'publicKey': pubKey }).then( (result) => {
                const rq = {
                    credentialId: result.id,
                    clientDataJSON: base64url.encode(result.response.clientDataJSON),
                    authenticatorData: base64url.encode(result.response.authenticatorData),
                    signature: base64url.encode(result.response.signature),
                };

                api.login({request: rq}).then((p) => {
                        setError(false);
                        setHelperText("");
                        props.loggedIn(p);
                    }
                ).catch((ex) => {
                    console.log(ex);
                    setError(true);
                    if (ex.statusText !== undefined) {
                        setHelperText("Login failed (" + ex.statusText + ").");
                    } else {
                        setHelperText("Login failed.");
                    }
                });
            }).catch( (pex) => {
                setError(true);
                setHelperText("Login failed - could not get credential.");
                console.log('get credential: ' + pex);
            });
        }).catch((e) => {
                console.log(e);
                setError(true);
                if (e.statusText !== undefined) {
                    setHelperText("Login failed (" + e.statusText + ").");
                } else {
                    setHelperText("Login failed.");
                }
            }
        );
    }

    const handleChange = event => {
        setUsername(event.target.value);
    };

    function _getAssertion (params) {
        let challenge = base64url.decode(params.challenge);
        let publicKey = {
            'challenge': challenge
        };
        if (params.uv) {
            publicKey.userVerification = 'required';
        } else {
            publicKey.userVerification = 'discouraged';
        }
        if (params.rpId) {
            publicKey.rpId = params.rpId;
        }

        if (params.credentials !== null && params.credentials.length > 0) {
            publicKey.allowCredentials = [];
            for (let i = 0; i < params.credentials.length; i++) {
                publicKey.allowCredentials.push({type: "public-key", id: base64url.decode(params.credentials[i])})
            }
        }
        return publicKey;
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign In
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="username"
                    name="username"
                    autoComplete="username"
                    label="User Name"
                    required
                    autoFocus
                    onChange={handleChange}
                    error={error}
                    helperText={helperText}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                    className={classes.submit}>
                    Sign In
                </Button>
            </div>
        </Container>
    );
}

export default SignIn;
