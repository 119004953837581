/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganisationCreateRequest
 */
export interface OrganisationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateRequest
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateRequest
     */
    address2: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateRequest
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateRequest
     */
    locality: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateRequest
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateRequest
     */
    registrationNumber: string;
}

export function OrganisationCreateRequestFromJSON(json: any): OrganisationCreateRequest {
    return OrganisationCreateRequestFromJSONTyped(json, false);
}

export function OrganisationCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address1': json['address1'],
        'address2': json['address2'],
        'city': json['city'],
        'country': json['country'],
        'locality': json['locality'],
        'name': json['name'],
        'postalCode': json['postalCode'],
        'registrationNumber': json['registrationNumber'],
    };
}

export function OrganisationCreateRequestToJSON(value?: OrganisationCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'country': value.country,
        'locality': value.locality,
        'name': value.name,
        'postalCode': value.postalCode,
        'registrationNumber': value.registrationNumber,
    };
}


