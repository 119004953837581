/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EntityResource,
    EntityResourceFromJSON,
    EntityResourceFromJSONTyped,
    EntityResourceToJSON,
    FolderResource,
    FolderResourceFromJSON,
    FolderResourceFromJSONTyped,
    FolderResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectResource
 */
export interface ProjectResource {
    /**
     * 
     * @type {Array<FolderResource>}
     * @memberof ProjectResource
     */
    folders?: Array<FolderResource>;
    /**
     * 
     * @type {number}
     * @memberof ProjectResource
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResource
     */
    name?: string;
    /**
     * 
     * @type {EntityResource}
     * @memberof ProjectResource
     */
    trust?: EntityResource;
}

export function ProjectResourceFromJSON(json: any): ProjectResource {
    return ProjectResourceFromJSONTyped(json, false);
}

export function ProjectResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'folders': !exists(json, 'folders') ? undefined : ((json['folders'] as Array<any>).map(FolderResourceFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'trust': !exists(json, 'trust') ? undefined : EntityResourceFromJSON(json['trust']),
    };
}

export function ProjectResourceToJSON(value?: ProjectResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'folders': value.folders === undefined ? undefined : ((value.folders as Array<any>).map(FolderResourceToJSON)),
        'id': value.id,
        'name': value.name,
        'trust': EntityResourceToJSON(value.trust),
    };
}


