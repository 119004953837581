import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import ExportIcon from "@material-ui/icons/AssignmentReturned";
import ViewIcon from "@material-ui/icons/ViewList";
import {ReportExportControllerApi} from "./api";
import {checkError, errOptions} from "./util";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    button: {
    },
    table: {
    },
}));

function Reports(props) {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [fromDate, setFromDate] = useState(new Date(new Date().setMonth(new Date().getMonth()-1)));
    const [toDate, setToDate] = useState(new Date());
    const [rows, setRows] = useState(null);

    const handleDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const handleView = () => {
        const api = new ReportExportControllerApi(props.conf);
        api.createReport({endDate: toDate, startDate: fromDate})
            .then( (r) => {
                setRows(r);
            })
            .catch( (e) => {
                checkError(e,history, () =>
                    enqueueSnackbar('Failed to retrieve report: ' + e.statusText,
                        errOptions))
            });
    }

    const handleCsv = () => {
        const api = new ReportExportControllerApi(props.conf);
        api.createReport({endDate: toDate, startDate: fromDate})
            .then( (r) => {
                let csv = "Signed Date, Signed By, Jurisdiction, Name, Entity, Tag\r\n";
                r.map( (row) => {
                    csv += moment(row.dateSigned).format("YYYY-MM-DD") +
                        "," + row.signer +"," + row.jurisdiction + "," + row.folder +"/" +
                        row.document+ ","+ row.trust +","+ row.tag+
                        "\r\n";
                });
                const element = document.createElement("a");
                const file = new Blob([csv], {type: 'text/csv'});
                element.href = URL.createObjectURL(file);
                element.download = "report.csv";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            })
            .catch( (e) => {
                checkError(e,history, () =>
                    enqueueSnackbar('Failed to retrieve report: ' + e.statusText,
                        errOptions))
            });
    }

    const reportTable = () => {
        return (
        <TableContainer component={Paper}>
            <Table aria-label="report-table" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Signed Date</TableCell>
                        <TableCell>Signed By</TableCell>
                        <TableCell>Jurisdiction</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Entity</TableCell>
                        <TableCell>Tag</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows != null && rows.map((row,idx) => (
                        <TableRow key={idx}>
                            <TableCell>
                                {moment(row.dateSigned).format("ddd, D MMM YYYY, HH:mm")}
                            </TableCell>
                            <TableCell>
                                {row.signer}
                            </TableCell>
                            <TableCell>{row.jurisdiction}</TableCell>
                            <TableCell>{row.folder + '/' + row.document}</TableCell>
                            <TableCell>{row.trust}</TableCell>
                            <TableCell>{row.tag}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        );
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container className={classes.root} spacing={2}
                  justify="center"
                  alignItems="center">

                <Grid item xs={12}>
                <h2>Report of signed documents</h2>
                </Grid>

                <Grid item xs={12}>
                <Grid container spacing={2} justify="center">
                <Grid item>
                <KeyboardDatePicker
                    id="date-picker-from"
                    label="From date"
                    value={fromDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </Grid>
                <Grid item>
                <KeyboardDatePicker
                    id="date-picker-to"
                    label="To date"
                    value={toDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </Grid>
                </Grid>
                </Grid>

                <Grid item xs={12}>
                <Grid container spacing={2} justify="center">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<ViewIcon/>}
                            onClick={handleView}>
                            View
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<ExportIcon/>}
                            onClick={handleCsv}>
                            Export
                        </Button>
                    </Grid>
                </Grid>
                </Grid>

                <Grid item xs={12}>
                    {
                        reportTable()
                    }
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>);
}


export default Reports;
