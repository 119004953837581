import React from 'react';
import ReactDOM from 'react-dom';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {SnackbarProvider} from 'notistack';
import {orange} from '@material-ui/core/colors';
import {
    HashRouter as Router,
} from "react-router-dom";
import {CookiesProvider} from 'react-cookie';
import './index.css';
import App from './App';
import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createMuiTheme({
    palette: {
        warning: {
            main: orange[500],
        }
    },
});

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <CookiesProvider>
                    <Router>
                        <App/>
                    </Router>
                </CookiesProvider>
            </SnackbarProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
