/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FIDO2DeviceRegisterRequest
 */
export interface FIDO2DeviceRegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof FIDO2DeviceRegisterRequest
     */
    attestationObject: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2DeviceRegisterRequest
     */
    challengeToken: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2DeviceRegisterRequest
     */
    clientDataJSON: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2DeviceRegisterRequest
     */
    credentialId: string;
    /**
     * 
     * @type {string}
     * @memberof FIDO2DeviceRegisterRequest
     */
    keyName?: string;
}

export function FIDO2DeviceRegisterRequestFromJSON(json: any): FIDO2DeviceRegisterRequest {
    return FIDO2DeviceRegisterRequestFromJSONTyped(json, false);
}

export function FIDO2DeviceRegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FIDO2DeviceRegisterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attestationObject': json['attestationObject'],
        'challengeToken': json['challengeToken'],
        'clientDataJSON': json['clientDataJSON'],
        'credentialId': json['credentialId'],
        'keyName': !exists(json, 'keyName') ? undefined : json['keyName'],
    };
}

export function FIDO2DeviceRegisterRequestToJSON(value?: FIDO2DeviceRegisterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attestationObject': value.attestationObject,
        'challengeToken': value.challengeToken,
        'clientDataJSON': value.clientDataJSON,
        'credentialId': value.credentialId,
        'keyName': value.keyName,
    };
}


