/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RegistrationCreateRequest,
    RegistrationCreateRequestFromJSON,
    RegistrationCreateRequestToJSON,
    UserResource,
    UserResourceFromJSON,
    UserResourceToJSON,
} from '../models';

export interface SendRegistrationNotificationRequest {
    resource: RegistrationCreateRequest;
}

export interface ShowRegistrationRequestRequest {
    token: string;
}

/**
 * 
 */
export class UserRegistrationControllerApi extends runtime.BaseAPI {

    /**
     * Send a registration notification
     */
    async sendRegistrationNotificationRaw(requestParameters: SendRegistrationNotificationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling sendRegistrationNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/user-registrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationCreateRequestToJSON(requestParameters.resource),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send a registration notification
     */
    async sendRegistrationNotification(requestParameters: SendRegistrationNotificationRequest): Promise<void> {
        await this.sendRegistrationNotificationRaw(requestParameters);
    }

    /**
     * Get the user for a registration token
     */
    async showRegistrationRequestRaw(requestParameters: ShowRegistrationRequestRequest): Promise<runtime.ApiResponse<UserResource>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling showRegistrationRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/user-registrations/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResourceFromJSON(jsonValue));
    }

    /**
     * Get the user for a registration token
     */
    async showRegistrationRequest(requestParameters: ShowRegistrationRequestRequest): Promise<UserResource> {
        const response = await this.showRegistrationRequestRaw(requestParameters);
        return await response.value();
    }

}
