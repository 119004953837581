/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileDeviceUpdateRequest
 */
export interface MobileDeviceUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceUpdateRequest
     */
    fcmToken?: string;
}

export function MobileDeviceUpdateRequestFromJSON(json: any): MobileDeviceUpdateRequest {
    return MobileDeviceUpdateRequestFromJSONTyped(json, false);
}

export function MobileDeviceUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileDeviceUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fcmToken': !exists(json, 'fcmToken') ? undefined : json['fcmToken'],
    };
}

export function MobileDeviceUpdateRequestToJSON(value?: MobileDeviceUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fcmToken': value.fcmToken,
    };
}


