/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BinaryResource,
    BinaryResourceFromJSON,
    BinaryResourceToJSON,
    CreateSignatureSessionRequest,
    CreateSignatureSessionRequestFromJSON,
    CreateSignatureSessionRequestToJSON,
    SessionReadyForLocationResource,
    SessionReadyForLocationResourceFromJSON,
    SessionReadyForLocationResourceToJSON,
    SessionReadyForSigningResource,
    SessionReadyForSigningResourceFromJSON,
    SessionReadyForSigningResourceToJSON,
    SignatureSessionCreateResource,
    SignatureSessionCreateResourceFromJSON,
    SignatureSessionCreateResourceToJSON,
    SignatureSessionResource,
    SignatureSessionResourceFromJSON,
    SignatureSessionResourceToJSON,
} from '../models';

export interface AcceptSignatureRequest {
    token: string;
}

export interface CreateSignatureSessionOperationRequest {
    resource: CreateSignatureSessionRequest;
}

export interface DownloadDocumentHashRequest {
    token: string;
}

export interface FindSignatureSessionRequest {
    token: string;
}

export interface ReadyForSigningRequest {
    token: string;
}

export interface UploadSignatureRequest {
    token: string;
    signature: BinaryResource;
}

/**
 * 
 */
export class SignatureSessionControllerApi extends runtime.BaseAPI {

    /**
     * acceptSignature
     */
    async acceptSignatureRaw(requestParameters: AcceptSignatureRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling acceptSignature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-sessions/{token}/accept`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * acceptSignature
     */
    async acceptSignature(requestParameters: AcceptSignatureRequest): Promise<void> {
        await this.acceptSignatureRaw(requestParameters);
    }

    /**
     * Create a signature session
     */
    async createSignatureSessionRaw(requestParameters: CreateSignatureSessionOperationRequest): Promise<runtime.ApiResponse<SignatureSessionCreateResource>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling createSignatureSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSignatureSessionRequestToJSON(requestParameters.resource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignatureSessionCreateResourceFromJSON(jsonValue));
    }

    /**
     * Create a signature session
     */
    async createSignatureSession(requestParameters: CreateSignatureSessionOperationRequest): Promise<SignatureSessionCreateResource> {
        const response = await this.createSignatureSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Download the document hash that needs to be signed
     */
    async downloadDocumentHashRaw(requestParameters: DownloadDocumentHashRequest): Promise<runtime.ApiResponse<BinaryResource>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling downloadDocumentHash.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-sessions/{token}/download-document`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResourceFromJSON(jsonValue));
    }

    /**
     * Download the document hash that needs to be signed
     */
    async downloadDocumentHash(requestParameters: DownloadDocumentHashRequest): Promise<BinaryResource> {
        const response = await this.downloadDocumentHashRaw(requestParameters);
        return await response.value();
    }

    /**
     * Find a signature session
     */
    async findSignatureSessionRaw(requestParameters: FindSignatureSessionRequest): Promise<runtime.ApiResponse<SignatureSessionResource>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling findSignatureSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-sessions/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignatureSessionResourceFromJSON(jsonValue));
    }

    /**
     * Find a signature session
     */
    async findSignatureSession(requestParameters: FindSignatureSessionRequest): Promise<SignatureSessionResource> {
        const response = await this.findSignatureSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * get a document session that is ready for signing
     */
    async getDocumentReadyForLocationRaw(): Promise<runtime.ApiResponse<SessionReadyForLocationResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-sessions/ready-for-location`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionReadyForLocationResourceFromJSON(jsonValue));
    }

    /**
     * get a document session that is ready for signing
     */
    async getDocumentReadyForLocation(): Promise<SessionReadyForLocationResource> {
        const response = await this.getDocumentReadyForLocationRaw();
        return await response.value();
    }

    /**
     * get a session that is ready for signing
     */
    async readyForSigningRaw(requestParameters: ReadyForSigningRequest): Promise<runtime.ApiResponse<SessionReadyForSigningResource>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling readyForSigning.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-sessions/{token}/ready-for-signing`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionReadyForSigningResourceFromJSON(jsonValue));
    }

    /**
     * get a session that is ready for signing
     */
    async readyForSigning(requestParameters: ReadyForSigningRequest): Promise<SessionReadyForSigningResource> {
        const response = await this.readyForSigningRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload a signature
     */
    async uploadSignatureRaw(requestParameters: UploadSignatureRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling uploadSignature.');
        }

        if (requestParameters.signature === null || requestParameters.signature === undefined) {
            throw new runtime.RequiredError('signature','Required parameter requestParameters.signature was null or undefined when calling uploadSignature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-sessions/{token}/upload-signature`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BinaryResourceToJSON(requestParameters.signature),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload a signature
     */
    async uploadSignature(requestParameters: UploadSignatureRequest): Promise<void> {
        await this.uploadSignatureRaw(requestParameters);
    }

}
