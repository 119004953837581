/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateRequest
     */
    organisationId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    role: string;
}

export function UserUpdateRequestFromJSON(json: any): UserUpdateRequest {
    return UserUpdateRequestFromJSONTyped(json, false);
}

export function UserUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'],
        'organisationId': !exists(json, 'organisationId') ? undefined : json['organisationId'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'role': json['role'],
    };
}

export function UserUpdateRequestToJSON(value?: UserUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'organisationId': value.organisationId,
        'phone': value.phone,
        'role': value.role,
    };
}


