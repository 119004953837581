/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileDeviceResource
 */
export interface MobileDeviceResource {
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceResource
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileDeviceResource
     */
    uid?: string;
}

export function MobileDeviceResourceFromJSON(json: any): MobileDeviceResource {
    return MobileDeviceResourceFromJSONTyped(json, false);
}

export function MobileDeviceResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileDeviceResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
    };
}

export function MobileDeviceResourceToJSON(value?: MobileDeviceResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'uid': value.uid,
    };
}


