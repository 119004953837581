import { useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/CloudUpload';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Link,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import AlertDialog from "./AlertDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    warningButton: {
        backgroundColor: theme.palette.warning.main,
    },
}));

function OrgForm(props) {
    const classes = useStyles();
    const history = useHistory();
    const [openConfirm, setOpenConfirm] = useState(false);

    const validationSchema = yup.object({
        name: yup
            .string('Organization name')
            .min(2, 'Name should be a minimum of 2 characters')
            .required('Organization name is required'),
        registrationNumber: yup
            .string('Registration number')
            .min(2, 'Number should be a minimum of 2 characters')
            .required('Registration number is required'),
        country: yup
            .string('Country')
            .min(2, 'Country should be a minimum of 2 characters')
            .required('Country is required'),
        address1: yup
            .string('Address 1')
            .min(2, 'Address should be a minimum of 2 characters')
            .required('Address is required'),
        address2: yup
            .string('Address 2')
            .optional(),
        city: yup
            .string('City')
            .min(2, 'City should be a minimum of 2 characters')
            .required('City is required'),
        locality: yup
            .string('State')
            .min(2, 'State should be a minimum of 2 characters')
            .required('State is required'),
        postalCode: yup
            .string('ZIP Code')
            .min(2, 'ZIP Code should be a minimum of 2 characters')
            .required('ZIP Code is required'),
    });

    const formik = useFormik({
        initialValues: props.org,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.onSubmit(values);
        },
    });

    const handleCancel = () => {
        history.goBack();
    };

    const handleConfirmDelete = () => {
        setOpenConfirm(true);
    };

    const handleClose = () => {
        setOpenConfirm(false);
    };

    const handleAccept = () => {
        setOpenConfirm(false);
        props.onDelete(props.org.id);
    };

    return (<>
        <AlertDialog open={openConfirm}
                     title={"Delete this organization?"}
                     description={"This action will delete the organization - please confirm."}
                     handleClose={handleClose} handleAccept={handleAccept}/>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                name="name"
                label="Name"
                autoFocus
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="registrationNumber"
                name="registrationNumber"
                label="Registration Number"
                value={formik.values.registrationNumber}
                onChange={formik.handleChange}
                error={formik.touched.registrationNumber && Boolean(formik.errors.registrationNumber)}
                helperText={formik.touched.registrationNumber && formik.errors.registrationNumber}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="country"
                name="country"
                label="Country"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="address1"
                name="address1"
                label="Address 1"
                value={formik.values.address1}
                onChange={formik.handleChange}
                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                helperText={formik.touched.address1 && formik.errors.address1}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="address2"
                name="address2"
                label="Address 2"
                value={formik.values.address2}
                onChange={formik.handleChange}
                error={formik.touched.address2 && Boolean(formik.errors.address2)}
                helperText={formik.touched.address2 && formik.errors.address2}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="city"
                name="city"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="locality"
                name="locality"
                label="State/Provence/Region"
                value={formik.values.locality}
                onChange={formik.handleChange}
                error={formik.touched.locality && Boolean(formik.errors.locality)}
                helperText={formik.touched.locality && formik.errors.locality}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="postalCode"
                name="postalCode"
                label="ZIP/Postal Code"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                helperText={formik.touched.postalCode && formik.errors.postalCode}
            />
            {props.org.id === undefined ?
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    startIcon={<UpdateIcon/>}>
                    Add
                </Button>
                    :
                <>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    startIcon={<UpdateIcon/>}>
                    Update
                </Button>
                <Button
                variant="contained"
                className={`${classes.warningButton} ${classes.button}`}
                onClick={handleConfirmDelete}
                startIcon={<DeleteIcon/>}>
                Remove
                </Button>
                </>
            }
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleCancel}
                startIcon={<CancelIcon />}>
                Cancel
            </Button>
        </form>
        </>
    );
}

function Organization(props) {
    const classes = useStyles();
    const match = useRouteMatch();

    return (
        <ListItem>
            <Card className={classes.root} variant="outlined">
                <CardHeader
                    avatar={
                        <Avatar aria-label="organization" className={classes.avatar}>
                            {props.org.name.charAt(0)}
                        </Avatar>
                    }
                    action={
                        <Link to={`${match.url}/edit/${props.org.id}`}>
                            <EditIcon />
                        </Link>
                    }
                    title={props.org.name}
                />
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {props.org.name}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {props.org.registrationNumber}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {props.org.address1}
                        <br />
                        {props.org.address2}
                        <br />
                        {props.org.city}
                        <br />
                        {props.org.postalCode}
                        <br />
                        {props.org.locality}
                        <br />
                        {props.org.country}
                        <br />
                        {!props.org.enabled &&
                        <p>Disabled <UncheckedIcon/></p>
                        }
                    </Typography>
                </CardContent>
            </Card>
        </ListItem>
    );
}

export { Organization, OrgForm };
