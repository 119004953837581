import React, {useState, useEffect, useContext} from 'react';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import {OrganisationControllerApi, RoleControllerApi, UserControllerApi, UserRegistrationControllerApi} from './api';
import { User, UserForm } from "./User";
import { checkError, errOptions} from "./util";
import AppContext from "./AppContext";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
}));

function Users(props) {
    const { users, setUsers } = useContext(AppContext);
    const classes = useStyles();
    const [roles, setRoles] = useState(null);
    const [orgs, setOrgs] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const match = useRouteMatch();
    const history = useHistory();
    const api = new UserControllerApi(props.conf);
    const registerApi = new UserRegistrationControllerApi(props.conf);

    function findUserIdx(id) {
        for (let i = 0; i < users.length; i++) {
            if (users[i].uid === id)
                return i;
        }
    }

    useEffect(() => {
        const rolesApi = new RoleControllerApi(props.conf);
        rolesApi.listRoles()
            .then( (roleresource) => {
                const orgsApi = new OrganisationControllerApi(props.conf);
                orgsApi.listOrganisations({})
                    .then(orglist => {
                        setOrgs(orglist.data);
                        setRoles(roleresource);
                    })
                    .catch(e => {
                        checkError(e,history, () =>
                            enqueueSnackbar('Failed to request organizations: ' + e.statusText,
                                errOptions))
                    });
            })
            .catch(e => {
                checkError(e,history, () =>
                    enqueueSnackbar('Failed to request roles: ' + e.statusText,
                        errOptions))
            });
    }, []);

    const updateUser = user => {

        let updateUser = {
            name: user.name,
            email: user.email,
            phone: user.phone,
            organisationId: user.organisationId,
            role: user.role,
        };
        api.updateUser({uid: user.uid, resource: updateUser})
            .then( (r) => {
                users[findUserIdx(r.uid)] = r;
                setUsers(users);
                history.push(match.path);
            })
            .catch( (e) => {
                checkError(e,history, () =>
                    enqueueSnackbar('Failed to update user: ' + e.statusText,
                        errOptions))
            });
    };

    const handleDeleteUser = id => {
        api.deleteUser({uid: id})
            .then( () => {
                let idx = findUserIdx(parseInt(id));
                users.splice(idx,1);
                setUsers(users);
                history.push(match.path);
            })
            .catch( (e) => {
                checkError(e,history, () =>
                    enqueueSnackbar('Failed to delete user: ' + e.statusText,
                        errOptions))
            });
    }

    const handleResetLogin = (user) => {
        registerApi.sendRegistrationNotification({resource: {uid: user.uid}})
            .then( () => {
                enqueueSnackbar('Login reset sent.',{autoHideDuration: 3000, variant: 'success'});
            })
            .catch( (e) => {
                checkError(e,history, () =>
                    enqueueSnackbar('Failed to send request: ' + e.statusText,
                        errOptions))
            });
    }

    const handleAddUser = user => {
        console.log("user "+ user);
        api.createUser({resource: user})
            .then( (r) => {
                setUsers([...users,r]);
                history.push(match.path);
                registerApi.sendRegistrationNotification({resource: {uid: r.uid}})
                    .then()
                    .catch( (e) => {
                        checkError(e,history, () =>
                            enqueueSnackbar('Failed to send user notification: ' + e.statusText,
                                errOptions))

                    });
            })
            .catch( (e) => {
                checkError(e,history, () =>
                    enqueueSnackbar('Failed to create user: ' + e.statusText,
                        errOptions))
            });
    };

    return (
        <>
            <Grid item xs={12} lg={6} className={classes.root}>
                <Switch>
                    <Route path={`${match.path}/add`} render={() => {
                        let user= { name: "", email: "", phone: "", role: roles[0], organisationId: orgs[0].id};
                        return (<UserForm roles={roles} orgs={orgs} user={user} onSubmit={handleAddUser}/>
                        );}
                    }/>
                    <Route path={`${match.path}/edit/:id`} render={routeProps => {
                        let user = users[findUserIdx(routeProps.match.params.id)];
                        user.phone = user.telephone;
                        return (<UserForm roles={roles} orgs={orgs} user={user} onSubmit={updateUser} onDelete={handleDeleteUser}/>
                        );}
                    }/>
                    <Route path={`${match.path}/`}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            component={Link} to={`${match.path}/add`}
                            startIcon={<AddIcon />}>
                            Add User
                        </Button>
                        <List>{users !== null && users.map((user) =>
                            <User edit key={user.uid} data-key={user.uid} user={user} onResetLogin={handleResetLogin}/> )}
                        </List>
                    </Route>
                </Switch>
            </Grid>
        </>
    );
}

export default Users;
