/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginResource
 */
export interface LoginResource {
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginResource
     */
    authorities?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LoginResource
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResource
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResource
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResource
     */
    uid?: string;
}

export function LoginResourceFromJSON(json: any): LoginResource {
    return LoginResourceFromJSONTyped(json, false);
}

export function LoginResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorities': !exists(json, 'authorities') ? undefined : json['authorities'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
    };
}

export function LoginResourceToJSON(value?: LoginResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorities': value.authorities,
        'email': value.email,
        'name': value.name,
        'token': value.token,
        'uid': value.uid,
    };
}


