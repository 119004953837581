/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationCreateRequest
 */
export interface RegistrationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof RegistrationCreateRequest
     */
    uid: string;
}

export function RegistrationCreateRequestFromJSON(json: any): RegistrationCreateRequest {
    return RegistrationCreateRequestFromJSONTyped(json, false);
}

export function RegistrationCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': json['uid'],
    };
}

export function RegistrationCreateRequestToJSON(value?: RegistrationCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
    };
}


