/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntityRequest
 */
export interface EntityRequest {
    /**
     * 
     * @type {string}
     * @memberof EntityRequest
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityRequest
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityRequest
     */
    city?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityRequest
     */
    classificationId: number;
    /**
     * 
     * @type {string}
     * @memberof EntityRequest
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityRequest
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityRequest
     */
    registrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityRequest
     */
    state?: string;
}

export function EntityRequestFromJSON(json: any): EntityRequest {
    return EntityRequestFromJSONTyped(json, false);
}

export function EntityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'classificationId': json['classificationId'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function EntityRequestToJSON(value?: EntityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'classificationId': value.classificationId,
        'country': value.country,
        'name': value.name,
        'postalCode': value.postalCode,
        'registrationNumber': value.registrationNumber,
        'state': value.state,
    };
}


