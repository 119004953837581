/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SignatureRequestCreateRequest,
    SignatureRequestCreateRequestFromJSON,
    SignatureRequestCreateRequestToJSON,
    SignatureRequestResource,
    SignatureRequestResourceFromJSON,
    SignatureRequestResourceToJSON,
    SignatureSessionResource,
    SignatureSessionResourceFromJSON,
    SignatureSessionResourceToJSON,
} from '../models';

export interface CreateSignatureRequestRequest {
    requestCreateRequest: SignatureRequestCreateRequest;
}

export interface SendSignatureRequestNotificationRequest {
    id: number;
}

export interface SessionsRequest {
    id: number;
}

export interface ShowSignatureRequestRequest {
    id: number;
}

/**
 * 
 */
export class SignatureRequestControllerApi extends runtime.BaseAPI {

    /**
     * Create a signature request
     */
    async createSignatureRequestRaw(requestParameters: CreateSignatureRequestRequest): Promise<runtime.ApiResponse<SignatureRequestResource>> {
        if (requestParameters.requestCreateRequest === null || requestParameters.requestCreateRequest === undefined) {
            throw new runtime.RequiredError('requestCreateRequest','Required parameter requestParameters.requestCreateRequest was null or undefined when calling createSignatureRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignatureRequestCreateRequestToJSON(requestParameters.requestCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignatureRequestResourceFromJSON(jsonValue));
    }

    /**
     * Create a signature request
     */
    async createSignatureRequest(requestParameters: CreateSignatureRequestRequest): Promise<SignatureRequestResource> {
        const response = await this.createSignatureRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * a list of signed signature requests
     */
    async listSignedSignatureRequestsRaw(): Promise<runtime.ApiResponse<Array<SignatureRequestResource>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-requests/signed-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignatureRequestResourceFromJSON));
    }

    /**
     * a list of signed signature requests
     */
    async listSignedSignatureRequests(): Promise<Array<SignatureRequestResource>> {
        const response = await this.listSignedSignatureRequestsRaw();
        return await response.value();
    }

    /**
     * Re-send signature request notification
     */
    async sendSignatureRequestNotificationRaw(requestParameters: SendSignatureRequestNotificationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendSignatureRequestNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Re-send signature request notification
     */
    async sendSignatureRequestNotification(requestParameters: SendSignatureRequestNotificationRequest): Promise<void> {
        await this.sendSignatureRequestNotificationRaw(requestParameters);
    }

    /**
     * get signature sessions
     */
    async sessionsRaw(requestParameters: SessionsRequest): Promise<runtime.ApiResponse<Array<SignatureSessionResource>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sessions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-requests/{id}/sessions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignatureSessionResourceFromJSON));
    }

    /**
     * get signature sessions
     */
    async sessions(requestParameters: SessionsRequest): Promise<Array<SignatureSessionResource>> {
        const response = await this.sessionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * show signature request
     */
    async showSignatureRequestRaw(requestParameters: ShowSignatureRequestRequest): Promise<runtime.ApiResponse<SignatureRequestResource>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling showSignatureRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignatureRequestResourceFromJSON(jsonValue));
    }

    /**
     * show signature request
     */
    async showSignatureRequest(requestParameters: ShowSignatureRequestRequest): Promise<SignatureRequestResource> {
        const response = await this.showSignatureRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * A list of unsigned signature requests for the authenticated user
     */
    async unsignedListSignatureRequestsRaw(): Promise<runtime.ApiResponse<Array<SignatureRequestResource>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/signature-requests/unsigned-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignatureRequestResourceFromJSON));
    }

    /**
     * A list of unsigned signature requests for the authenticated user
     */
    async unsignedListSignatureRequests(): Promise<Array<SignatureRequestResource>> {
        const response = await this.unsignedListSignatureRequestsRaw();
        return await response.value();
    }

}
