import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {TrustControllerApi} from "./api";
import {checkError, errOptions} from "./util";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import InputLabel from '@material-ui/core/InputLabel';

function EntityForm(props) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [entity, setEntity] = useState(props.entity);
    const [classification, setClassification] = useState(0);

    useEffect( () => {
        let e = props.entity;
        if (e.name == null) e.name = '';
        if (e.registrationNumber == null) e.registrationNumber = '';
        if (e.country == null) e.country = '';
        if (e.address1 == null) e.address1 = '';
        if (e.address2 == null) e.address2 = '';
        if (e.city == null) e.city = '';
        if (e.state == null) e.state = '';
        if (e.postalCode == null) e.postalCode = '';
        if (e.classification != null) setClassification(e.classification.id);
        setEntity(e);
    },[props.entity]);

    const handleEditEntityAccept = (updated) => {
        const api = new TrustControllerApi(props.conf);
        updated.classificationId = classification;
        if (props.add) {
            if (props.entities.find( (p) => { return p.name === updated.name})) {
                enqueueSnackbar('Entity already exists: ' + updated.name,
                    errOptions);
                return;
            }
            api.createTrust( { request: updated})
                .then( (r) => {
                    props.onSubmit(r);
                })
                .catch( (e) => checkError(e,history, () =>
                    enqueueSnackbar('Failed to add entity: ' + e.statusText,
                        errOptions)));
        } else {
            api.updateTrust({
                trustId: entity.id, request: updated
            })
                .then((r) => props.onSubmit(r))
                .catch((e) => checkError(e, history, () =>
                    enqueueSnackbar('Failed to update entity: ' + e.statusText,
                        errOptions)));
        }
    }

    const validationSchema = yup.object({
        name: yup
            .string('Entity name')
            .min(2, 'Name should be a minimum of 2 characters')
            .required('Entity name is required'),
        registrationNumber: yup
            .string('Registration number')
            .min(2, 'Number should be a minimum of 2 characters')
            .required('Registration number is required'),
        country: yup
            .string('Country')
            .min(2, 'Country should be a minimum of 2 characters')
            .required('Country is required'),
        address1: yup
            .string('Address 1')
            .min(2, 'Address should be a minimum of 2 characters')
            .required('Address is required'),
        address2: yup
            .string('Address 2')
            .optional(),
        city: yup
            .string('City')
            .min(2, 'City should be a minimum of 2 characters')
            .required('City is required'),
        state: yup
            .string('State')
            .min(2, 'State should be a minimum of 2 characters')
            .required('State is required'),
        postalCode: yup
            .string('ZIP Code')
            .min(2, 'ZIP Code should be a minimum of 2 characters')
            .required('ZIP Code is required'),
    });

    const formik = useFormik({
        initialValues: entity,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleEditEntityAccept(values);
        },
    });

    const onEntered = () => {
        formik.resetForm({ values: entity});
        if (entity.classification != null)
            setClassification(entity.classification.id)
    };

    return (<>
            <Dialog open={props.open}
                    onClose={props.handleClose}
                    onEntered={onEntered}>
                <DialogTitle>{props.title}</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <TextField
                            disabled={props.disabled}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            name="name"
                            label="Name"
                            autoFocus
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            disabled={props.disabled}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="registrationNumber"
                            name="registrationNumber"
                            label="Registration Number"
                            value={formik.values.registrationNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.registrationNumber && Boolean(formik.errors.registrationNumber)}
                            helperText={formik.touched.registrationNumber && formik.errors.registrationNumber}
                        />
                        <InputLabel htmlFor="classification-native">Class</InputLabel>
                        <Select
                            disabled={props.disabled}
                            native
                            fullWidth
                            value={classification}
                            onChange={(e) => setClassification(e.target.value)}
                            inputProps={{
                                name: 'classification',
                                id: 'classification-native',
                            }}
                        >
                            {props.classifications != null && props.classifications.map( (classification) =>
                                <option key={classification.id} value={classification.id}>{classification.name}</option>)
                            }
                            <option key={0} value={0}> </option>)
                        </Select>

                        <Typography variant="h6">Registered Office:</Typography>
                        <TextField
                            disabled={props.disabled}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="address1"
                            name="address1"
                            label="Address 1"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            error={formik.touched.address1 && Boolean(formik.errors.address1)}
                            helperText={formik.touched.address1 && formik.errors.address1}
                        />
                        <TextField
                            disabled={props.disabled}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="address2"
                            name="address2"
                            label="Address 2"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            error={formik.touched.address2 && Boolean(formik.errors.address2)}
                            helperText={formik.touched.address2 && formik.errors.address2}
                        />
                        <TextField
                            disabled={props.disabled}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="city"
                            name="city"
                            label="City"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                        />
                        <TextField
                            disabled={props.disabled}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="state"
                            name="state"
                            label="State/Provence/Region"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                        />
                        <TextField
                            disabled={props.disabled}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="postalCode"
                            name="postalCode"
                            label="ZIP/Postal Code"
                            value={formik.values.postalCode}
                            onChange={formik.handleChange}
                            error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                            helperText={formik.touched.postalCode && formik.errors.postalCode}
                        />
                        <TextField
                            disabled={props.disabled}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="country"
                            name="country"
                            label="Country"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            error={formik.touched.country && Boolean(formik.errors.country)}
                            helperText={formik.touched.country && formik.errors.country}
                        />
                    </DialogContent>
                    <DialogActions>
                        {props.disabled ?
                            <Button onClick={props.handleClose} color="primary" autoFocus>
                                Close
                            </Button>
                            :
                            <>
                                <Button onClick={props.handleClose} color="primary" autoFocus>
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary">
                                    OK
                                </Button>
                            </>
                        }
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

export default EntityForm;
