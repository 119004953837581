/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FolderRequest,
    FolderRequestFromJSON,
    FolderRequestToJSON,
    FolderResource,
    FolderResourceFromJSON,
    FolderResourceToJSON,
} from '../models';

export interface CreateFolderRequest {
    request: FolderRequest;
}

export interface ListFoldersRequest {
    projectId: number;
}

export interface ShowFolderRequest {
    folder: number;
}

export interface UpdateFolderRequest {
    folderId: number;
    request: FolderRequest;
}

/**
 * 
 */
export class FolderControllerApi extends runtime.BaseAPI {

    /**
     * createFolder
     */
    async createFolderRaw(requestParameters: CreateFolderRequest): Promise<runtime.ApiResponse<FolderResource>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling createFolder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/folders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FolderRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderResourceFromJSON(jsonValue));
    }

    /**
     * createFolder
     */
    async createFolder(requestParameters: CreateFolderRequest): Promise<FolderResource> {
        const response = await this.createFolderRaw(requestParameters);
        return await response.value();
    }

    /**
     * listFolders
     */
    async listFoldersRaw(requestParameters: ListFoldersRequest): Promise<runtime.ApiResponse<Array<FolderResource>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listFolders.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/folders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FolderResourceFromJSON));
    }

    /**
     * listFolders
     */
    async listFolders(requestParameters: ListFoldersRequest): Promise<Array<FolderResource>> {
        const response = await this.listFoldersRaw(requestParameters);
        return await response.value();
    }

    /**
     * showFolder
     */
    async showFolderRaw(requestParameters: ShowFolderRequest): Promise<runtime.ApiResponse<FolderResource>> {
        if (requestParameters.folder === null || requestParameters.folder === undefined) {
            throw new runtime.RequiredError('folder','Required parameter requestParameters.folder was null or undefined when calling showFolder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/folders/{folder}`.replace(`{${"folder"}}`, encodeURIComponent(String(requestParameters.folder))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderResourceFromJSON(jsonValue));
    }

    /**
     * showFolder
     */
    async showFolder(requestParameters: ShowFolderRequest): Promise<FolderResource> {
        const response = await this.showFolderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the folder
     */
    async updateFolderRaw(requestParameters: UpdateFolderRequest): Promise<runtime.ApiResponse<FolderResource>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling updateFolder.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling updateFolder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/folders/{folderId}`.replace(`{${"folderId"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FolderRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderResourceFromJSON(jsonValue));
    }

    /**
     * Update the folder
     */
    async updateFolder(requestParameters: UpdateFolderRequest): Promise<FolderResource> {
        const response = await this.updateFolderRaw(requestParameters);
        return await response.value();
    }

}
