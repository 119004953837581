/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserLoginRequest
 */
export interface UserLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    authenticatorData?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    clientDataJSON?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    credentialId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    signature?: string;
}

export function UserLoginRequestFromJSON(json: any): UserLoginRequest {
    return UserLoginRequestFromJSONTyped(json, false);
}

export function UserLoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLoginRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authenticatorData': !exists(json, 'authenticatorData') ? undefined : json['authenticatorData'],
        'clientDataJSON': !exists(json, 'clientDataJSON') ? undefined : json['clientDataJSON'],
        'credentialId': !exists(json, 'credentialId') ? undefined : json['credentialId'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
    };
}

export function UserLoginRequestToJSON(value?: UserLoginRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authenticatorData': value.authenticatorData,
        'clientDataJSON': value.clientDataJSON,
        'credentialId': value.credentialId,
        'signature': value.signature,
    };
}


