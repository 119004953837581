import React, {useState, useEffect} from "react";
import Box from "@material-ui/core/Box";
import './App.css';
import SignIn from "./SignIn";
import Main from "./Main";
import {
    Switch,
    Route,
    useHistory,
    matchPath, useLocation
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import Copyright from "./Copyright";
import Register from "./Register";

function App() {
    const [cookies, setCookie, removeCookie] = useCookies(['auth']);
    const history = useHistory();
    const location = useLocation();
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (matchPath(location.pathname, {path: '/register'}) == null) {
            if (cookies.auth != null) {
                if (user == null) {
                    try {
                        setUser(cookies.auth);
                    } catch (ex) {
                        console.log("Failed to parse cookie: " + cookies.auth);
                        removeCookie('auth');
                    }
                }
            } else {
                if (user == null) {
                    history.replace("/login");
                }
            }
        }
    }, [user, location.pathname, cookies, history, removeCookie]);

    const loggedInHandler = user => {
        setCookie('auth', JSON.stringify(user), {secure: true, sameSite: "strict"});
        setUser(user);
        history.replace("/main");
    };

    return (
        <>
            <div className="App">
                <Switch>
                    <Route path="/main">
                        {user !== null &&
                        <Main login={user}/>
                        }
                    </Route>
                    <Route path="/register/:token">
                        <Register/>
                    </Route>
                    <Route path="/logout" render={() => {
                        removeCookie('auth');
                        setUser(null);
                    }}/>
                    <Route path="/">
                        <SignIn loggedIn={loggedInHandler}/>
                    </Route>
                </Switch>
            </div>
            <footer className="copyright">
                <Box mt={2}>
                    <Copyright/>
                </Box>
            </footer>
        </>
    );
}

export default App;
