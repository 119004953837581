import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import SignIcon from "@material-ui/icons/VpnKey";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import CardActions from "@material-ui/core/CardActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import {checkError, errOptions} from "./util";
import { SignatureRequestControllerApi, SignatureSessionControllerApi, SignatureRequestDocumentControllerApi } from "./api";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function Dashboard(props) {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const api = new SignatureRequestControllerApi(props.conf);
    const [signatureRequests, setSignatureRequests] = useState(null);

    useEffect(() => {
        api.unsignedListSignatureRequests()
            .then( (s) => {
                setSignatureRequests(s);
            })
            .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to list signature requests: ' + e.statusText,
                    errOptions)));

    },[]);

    return (
        <Grid item xs={12} lg={6} className={classes.root}>
            { signatureRequests !== null && signatureRequests.length >0 ?
                <>
                <h2>Documents awaiting signature</h2>
                <List className={classes.root}>{signatureRequests.map((signatureRequest) =>
                <SignatureRequest conf={props.conf}
                                  key={signatureRequest.id}
                                  signatureRequest={signatureRequest}/> )}
                </List>
                </> :
                <h2>No documents awaiting signature.</h2>
            }
        </Grid>
);

}

const sigStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function SignatureRequest(props) {
    const classes = sigStyles();
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const api = new SignatureSessionControllerApi(props.conf);

    const handleSign = () => {
        api.createSignatureSession({ resource:
                {
                    signatureRequestId: props.signatureRequest.id
                }})
            .then( (r) => {
                window.location.href =
                    "fidoocle-signer://?token=" +
                    r.sessionId +
                    "&jwt=" +
                    r.jwt +
                    "&api=" +
                    props.conf.basePath;
            })
            .catch( (e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to send signature request: ' + e.statusText,
                    errOptions)));
    };

    const handleDownload = () => {
        const api = new SignatureRequestDocumentControllerApi(props.conf);
        api.downloadDocument({signatureRequestId: props.signatureRequest.id})
            .then( (file) => {
                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = props.signatureRequest.document.name;
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            })
            .catch( (e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to download file: ' + e.statusText,
                    errOptions)));
    };

    return (
        <ListItem key={props.signatureRequest.id}>
            <Card className={classes.root} variant="outlined">
                <CardHeader
                    avatar={
                        <Avatar aria-label="user">
                            {props.signatureRequest.requester.name.charAt(0)}
                        </Avatar>
                    }
                    title={props.signatureRequest.requester.name}
                />

                <CardContent>
                    <Typography variant="h5" component="h4">
                        {props.signatureRequest.document.name}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <Tooltip title="Sign document" aria-label="sign-document">
                        <IconButton aria-label="sign-document" onClick={ handleSign }>
                            <SignIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download document" aria-label="download-document">
                        <IconButton aria-label="download-document" onClick={ handleDownload }>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
        </ListItem>
    );
}

export default Dashboard;
