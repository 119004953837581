/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportExportRowItemResource
 */
export interface ReportExportRowItemResource {
    /**
     * 
     * @type {string}
     * @memberof ReportExportRowItemResource
     */
    dateSigned?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExportRowItemResource
     */
    document?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExportRowItemResource
     */
    folder?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExportRowItemResource
     */
    jurisdiction?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportExportRowItemResource
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportExportRowItemResource
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportExportRowItemResource
     */
    project?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExportRowItemResource
     */
    signer?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExportRowItemResource
     */
    tag?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExportRowItemResource
     */
    trust?: string;
}

export function ReportExportRowItemResourceFromJSON(json: any): ReportExportRowItemResource {
    return ReportExportRowItemResourceFromJSONTyped(json, false);
}

export function ReportExportRowItemResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportExportRowItemResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateSigned': !exists(json, 'dateSigned') ? undefined : json['dateSigned'],
        'document': !exists(json, 'document') ? undefined : json['document'],
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
        'jurisdiction': !exists(json, 'jurisdiction') ? undefined : json['jurisdiction'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'project': !exists(json, 'project') ? undefined : json['project'],
        'signer': !exists(json, 'signer') ? undefined : json['signer'],
        'tag': !exists(json, 'tag') ? undefined : json['tag'],
        'trust': !exists(json, 'trust') ? undefined : json['trust'],
    };
}

export function ReportExportRowItemResourceToJSON(value?: ReportExportRowItemResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateSigned': value.dateSigned,
        'document': value.document,
        'folder': value.folder,
        'jurisdiction': value.jurisdiction,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'project': value.project,
        'signer': value.signer,
        'tag': value.tag,
        'trust': value.trust,
    };
}


