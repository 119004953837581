import React from 'react';
const AppContext = React.createContext({
    users: [],
    setUsers: () => {},
    user: {},
    setUser: () => {},
    basePath: window.location.protocol + "//" + window.location.host
});
export default AppContext;

