/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignatureSessionCreateResource
 */
export interface SignatureSessionCreateResource {
    /**
     * 
     * @type {string}
     * @memberof SignatureSessionCreateResource
     */
    jwt?: string;
    /**
     * 
     * @type {string}
     * @memberof SignatureSessionCreateResource
     */
    sessionId?: string;
}

export function SignatureSessionCreateResourceFromJSON(json: any): SignatureSessionCreateResource {
    return SignatureSessionCreateResourceFromJSONTyped(json, false);
}

export function SignatureSessionCreateResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignatureSessionCreateResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jwt': !exists(json, 'jwt') ? undefined : json['jwt'],
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
    };
}

export function SignatureSessionCreateResourceToJSON(value?: SignatureSessionCreateResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jwt': value.jwt,
        'sessionId': value.sessionId,
    };
}


