/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FIDO2ParametersResource,
    FIDO2ParametersResourceFromJSON,
    FIDO2ParametersResourceToJSON,
    LoginResource,
    LoginResourceFromJSON,
    LoginResourceToJSON,
    UserLoginRequest,
    UserLoginRequestFromJSON,
    UserLoginRequestToJSON,
} from '../models';

export interface GetAssertionParametersRequest {
    email: string;
}

export interface LoginRequest {
    request: UserLoginRequest;
}

/**
 * 
 */
export class LoginControllerApi extends runtime.BaseAPI {

    /**
     * Retrieve assertion parameters.
     */
    async getAssertionParametersRaw(requestParameters: GetAssertionParametersRequest): Promise<runtime.ApiResponse<FIDO2ParametersResource>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling getAssertionParameters.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FIDO2ParametersResourceFromJSON(jsonValue));
    }

    /**
     * Retrieve assertion parameters.
     */
    async getAssertionParameters(requestParameters: GetAssertionParametersRequest): Promise<FIDO2ParametersResource> {
        const response = await this.getAssertionParametersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Login user
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<LoginResource>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResourceFromJSON(jsonValue));
    }

    /**
     * Login user
     */
    async login(requestParameters: LoginRequest): Promise<LoginResource> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

}
