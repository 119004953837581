/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganisationResource,
    OrganisationResourceFromJSON,
    OrganisationResourceFromJSONTyped,
    OrganisationResourceToJSON,
    RoleResource,
    RoleResourceFromJSON,
    RoleResourceFromJSONTyped,
    RoleResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserResource
 */
export interface UserResource {
    /**
     * 
     * @type {boolean}
     * @memberof UserResource
     */
    downloadedDesktopApplication?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResource
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    name?: string;
    /**
     * 
     * @type {OrganisationResource}
     * @memberof UserResource
     */
    organisation?: OrganisationResource;
    /**
     * 
     * @type {boolean}
     * @memberof UserResource
     */
    readInstructions?: boolean;
    /**
     * 
     * @type {RoleResource}
     * @memberof UserResource
     */
    role?: RoleResource;
    /**
     * 
     * @type {boolean}
     * @memberof UserResource
     */
    signedDocument?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    uid?: string;
}

export function UserResourceFromJSON(json: any): UserResource {
    return UserResourceFromJSONTyped(json, false);
}

export function UserResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'downloadedDesktopApplication': !exists(json, 'downloadedDesktopApplication') ? undefined : json['downloadedDesktopApplication'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organisation': !exists(json, 'organisation') ? undefined : OrganisationResourceFromJSON(json['organisation']),
        'readInstructions': !exists(json, 'readInstructions') ? undefined : json['readInstructions'],
        'role': !exists(json, 'role') ? undefined : RoleResourceFromJSON(json['role']),
        'signedDocument': !exists(json, 'signedDocument') ? undefined : json['signedDocument'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
    };
}

export function UserResourceToJSON(value?: UserResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'downloadedDesktopApplication': value.downloadedDesktopApplication,
        'email': value.email,
        'enabled': value.enabled,
        'name': value.name,
        'organisation': OrganisationResourceToJSON(value.organisation),
        'readInstructions': value.readInstructions,
        'role': RoleResourceToJSON(value.role),
        'signedDocument': value.signedDocument,
        'telephone': value.telephone,
        'uid': value.uid,
    };
}


