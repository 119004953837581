/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagResource
 */
export interface TagResource {
    /**
     * 
     * @type {number}
     * @memberof TagResource
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TagResource
     */
    name: string;
}

export function TagResourceFromJSON(json: any): TagResource {
    return TagResourceFromJSONTyped(json, false);
}

export function TagResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function TagResourceToJSON(value?: TagResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}


