import { useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/CloudUpload';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import EditIcon from '@material-ui/icons/Edit';
import ResetLoginIcon from '@material-ui/icons/RotateLeft';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Link,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import AlertDialog from "./AlertDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    warningButton: {
        backgroundColor: theme.palette.warning.main,
    },
}));

function UserForm(props) {
    const classes = useStyles();
    const history = useHistory();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [role, setRole] = useState(props.user.role.slug);
    const [org, setOrg] = useState(props.orgs[0].id);

    const validationSchema = yup.object({
        name: yup
            .string('User name')
            .min(2, 'Name should be a minimum of 2 characters')
            .required('User name is required'),
        email: yup
            .string('email')
            .min(2, 'Email should be valid')
            .required('Email is required'),
        phone: yup
            .string('Phone number')
            .min(2, 'Phone number should be valid')
            .required('Phone number is required'),
    });

    const handleSubmit = (user) => {
        user.role = role;
        user.organisationId = org;
        props.onSubmit(user);
    };

    const formik = useFormik({
        initialValues: props.user,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    const handleCancel = () => {
        history.goBack();
    };

    const handleConfirmDelete = () => {
        setOpenConfirm(true);
    };

    const handleClose = () => {
        setOpenConfirm(false);
    };

    const handleAccept = () => {
        setOpenConfirm(false);
        props.onDelete(props.user.uid);
    };

    const handleOrgChange = (r) => {
        setOrg(r.target.value);
    };

    const handleRoleChange = (r) => {
        setRole(r.target.value);
    };

    return (<>
        <AlertDialog open={openConfirm} handleClose={handleClose} handleAccept={handleAccept}/>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                name="name"
                label="Name"
                autoFocus
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="phone"
                name="phone"
                label="Phone number"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
            />
            <Select
                native
                fullWidth
                value={role}
                onChange={handleRoleChange}
                inputProps={{
                    name: 'role',
                    id: 'role-native',
                }}
            >
            {props.roles.map( (role) =>
                <option key={role.id} value={role.slug}>{role.name}</option>)
            }
            </Select>
            <br/>
            <Select
                native
                fullWidth
                value={org}
                onChange={handleOrgChange}
                inputProps={{
                    name: 'org',
                    id: 'org-native',
                }}
            >
                {props.orgs.map( (org) =>
                    org.enabled &&
                        <option key={org.id} value={org.id}>{org.name}</option>)
                }
            </Select>
            <br/>
            {props.user.uid === undefined ?
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    startIcon={<UpdateIcon/>}>
                    Add
                </Button>
                    :
                <>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    startIcon={<UpdateIcon/>}>
                    Update
                </Button>
                <Button
                variant="contained"
                className={`${classes.warningButton} ${classes.button}`}
                onClick={handleConfirmDelete}
                startIcon={<DeleteIcon/>}>
                Remove
                </Button>
                </>
            }
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleCancel}
                startIcon={<CancelIcon />}>
                Cancel
            </Button>
        </form>
        </>
    );
}

function User(props) {
    const classes = useStyles();
    const match = useRouteMatch();

    return (
        <ListItem>
            <Card className={classes.root} variant="outlined">
                <CardHeader
                    avatar={
                        <Avatar aria-label="user" className={classes.avatar}>
                            {props.user.name.charAt(0)}
                        </Avatar>
                    }
                    action={
                        props.edit &&
                        <Link to={`${match.url}/edit/${props.user.uid}`}>
                            <EditIcon />
                        </Link>
                    }
                    title={props.user.name}
                />
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {props.user.name}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {props.user.email}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {props.user.organisation.name}
                        <br />
                        {props.user.telephone}
                        <br />
                        Role: {props.user.role.name}
                        <br />
                        {!props.user.enabled &&
                        <p>Disabled <UncheckedIcon/></p>
                        }
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    {props.edit &&
                    <Tooltip title="Reset login" aria-label="reset login">
                        <IconButton aria-label="Reset login" onClick={() => props.onResetLogin(props.user)}>
                            <ResetLoginIcon/>
                        </IconButton>
                    </Tooltip>
                    }
                </CardActions>
            </Card>
        </ListItem>
    );
}

export { User, UserForm };
