/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserCreateRequest
     */
    organisationId: number;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    role: string;
}

export function UserCreateRequestFromJSON(json: any): UserCreateRequest {
    return UserCreateRequestFromJSONTyped(json, false);
}

export function UserCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'],
        'organisationId': json['organisationId'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'role': json['role'],
    };
}

export function UserCreateRequestToJSON(value?: UserCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'organisationId': value.organisationId,
        'phone': value.phone,
        'role': value.role,
    };
}


