/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSignatureSessionRequest
 */
export interface CreateSignatureSessionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateSignatureSessionRequest
     */
    signatureRequestId: number;
}

export function CreateSignatureSessionRequestFromJSON(json: any): CreateSignatureSessionRequest {
    return CreateSignatureSessionRequestFromJSONTyped(json, false);
}

export function CreateSignatureSessionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSignatureSessionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signatureRequestId': json['signatureRequestId'],
    };
}

export function CreateSignatureSessionRequestToJSON(value?: CreateSignatureSessionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signatureRequestId': value.signatureRequestId,
    };
}


