import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import NewFolderIcon from "@material-ui/icons/CreateNewFolder";
import TrustIcon from "@material-ui/icons/Group";
import InfoIcon from '@material-ui/icons/Info';
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {ProjectControllerApi} from "./api";
import {checkError, errOptions} from "./util";
import Project from "./Project";
import EntityForm from "./EntityForm";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        margin: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function Entity(props) {
    const classes = useStyles();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const [open, setOpen] = useState(true);
    const [openEntityEditor, setOpenEntityEditor] = useState(false);
    const [entityInfo, setEntityInfo] = useState(false);
    const [entity, setEntity] = useState(props.entity);

    const [projects, setProjects] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [projectError, setProjectError] = useState(null);

    useEffect(() => {
        const api = new ProjectControllerApi(props.conf);
        api.listMainFolders({trustId: entity.id})
            .then((t) => {
                setProjects(t);
            })
            .catch();
    }, []);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleAddProject = () => {
        if (projectName == null || projectName.length === 0) {
            setProjectError("Invalid folder name");
        } else {
            if (projects.find((p) => {
                return p.name === projectName
            })) {
                setProjectError("Already exists");
                return;
            }

            const api = new ProjectControllerApi(props.conf);
            api.createMainFolder({
                request: {
                    name: projectName,
                    entityId: entity.id
                }
            })
                .then((r) => {
                    setProjects([...projects, r]);
                    setProjectError(null);
                })
                .catch((e) => checkError(e, history, () =>
                    enqueueSnackbar('Failed to add folder: ' + e.statusText,
                        errOptions)));
        }
    };

    const handleEditEntityClose = () => {
        setOpenEntityEditor(false);
    }

    const handleEditEntityAccept = (updatedEntity) => {
        setOpenEntityEditor(false);
        setEntity(updatedEntity);
    }

    return (<>
            <EntityForm conf={props.conf}
                        title={entityInfo ? "Entity Information" : "Update entity"}
                        open={openEntityEditor}
                        handleClose={handleEditEntityClose}
                        onSubmit={handleEditEntityAccept}
                        entity={entity}
                        disabled={entityInfo}
                        classifications={props.classifications}
            />

            <ListItem key={entity.id}>
                <ListItemIcon><TrustIcon/></ListItemIcon>
                <ListItemText primary={entity.name} secondary={entity.organisation.name}/>
                <IconButton aria-label="info-entity" onClick={() => {
                    setEntityInfo(true);
                    setOpenEntityEditor(true);
                }}>
                    <InfoIcon/>
                </IconButton>
                <IconButton aria-label="edit-entity" onClick={() => {
                    setEntityInfo(false);
                    setOpenEntityEditor(true);
                }}>
                    <EditIcon/>
                </IconButton>
            </ListItem>
            <ListItem className={classes.nested} key={entity.id + 'bt'} button onClick={handleClick}>
                <ListItemText primary="Main Folders"/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <ListItem key={entity.id + 'add'}>
                    <Button
                        onClick={handleAddProject}
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        startIcon={<NewFolderIcon/>}>
                        Add Main Folder
                    </Button>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        name="name"
                        label="Main Folder Name"
                        value={projectName}
                        helperText={projectError}
                        error={projectError != null}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                </ListItem>

                <List component="div" disablePadding className={classes.nested}>
                    {projects !== null && projects.map((project) =>
                        <Project conf={props.conf} key={project.id} data-key={project.id} project={project}
                                 projects={projects} tags={props.tags}/>)
                    }
                </List>
            </Collapse>
        </>
    );
}
export default Entity;
