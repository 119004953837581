import React, {useContext} from "react";
import Grid from "@material-ui/core/Grid";
// import PhonesIcon from '@material-ui/icons/PhonelinkSetup';
import Devices from "./Devices";
import {User} from "./User";
import AppContext from "./AppContext";
import SecurityKeys from "./SecurityKeys";

function Profile(props) {
    const {user} = useContext(AppContext);
    return (<>
        <Grid container justify="center">
            <Grid item xs={12}>
                <Devices conf={props.conf} user={user}/>
            </Grid>
            <Grid item xs={12} lg={8}>
                <User key={user.uid} data-key={user.uid} user={user}/>
            </Grid>
            <Grid item xs={12} lg={6}>
                <SecurityKeys conf={props.conf}/>
            </Grid>
        </Grid>
    </>);
}

export default Profile;
