import base64url from "./base64";

const errOptions = {autoHideDuration: 3000, variant: 'error'}

function getPubKeyFromParams(fidoParameters) {
    const id = new Uint8Array(fidoParameters.userId.length);
    for (let i = 0; i < fidoParameters.userId.length; i++) {
        let parsed = parseInt(fidoParameters.userId.charAt(i), 16);
        if (isNaN(parsed)) {
            parsed = 0;
        }
        id[i] = parsed;
    }
    let excludes = [];
    if (fidoParameters.credentials !== undefined) {
        for (let i = 0; i < fidoParameters.credentials.length; i++) {
            excludes.push({
                type: "public-key",
                id: base64url.decode(fidoParameters.credentials[i])
            });
        }
    }
    return {
        attestation: fidoParameters.attestation,
        authenticatorSelection: {
            authenticatorAttachment: fidoParameters.authenticator,
            requireResidentKey: fidoParameters.rk,
            userVerification: fidoParameters.uv ? 'required' : 'discouraged',
        },
        challenge: base64url.decode(fidoParameters.challenge),
        rp: {
            name: fidoParameters.rpName,
            id: fidoParameters.rpId,
        },
        user: {
            id: id,
            name: fidoParameters.userName,
            displayName: fidoParameters.userDisplayName,
        },
        pubKeyCredParams: [
            {alg: -7, type: 'public-key'},
            {alg: -257, type: 'public-key'},
        ],
        excludeCredentials: excludes
    };
}

function checkError(e, history, msgFunc) {
    if (e.status === 403) {
        console.log("Auth failed - redirecting to login page.");
        history.replace("/login");
    } else {
        console.log(e);
        msgFunc();
    }
}

export { checkError, errOptions, getPubKeyFromParams};
