import React, {useContext, useEffect, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import {Configuration, RegisterControllerApi} from "./api";
import base64url from './base64';
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import AppContext from "./AppContext";
import {getPubKeyFromParams} from './util';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

function Register(props) {
    const { basePath } = useContext(AppContext);
    const [registered, setRegistered] = useState(false);
    const [message, setMessage] = useState("Registering security key...");
    const match = useRouteMatch();
    const classes = useStyles();

    useEffect(() => {
        const params = {basePath: basePath};
        const conf = new Configuration(params);
        const api = new RegisterControllerApi(conf);
        api.getParametersBySignupToken({ token: match.params.token})
            .then( (fidoParameters) => {
                let pub = getPubKeyFromParams(fidoParameters);
                navigator.credentials.create({
                    publicKey: pub,
                }).then( (r) => {
                    api.signUp({
                        userRegisterCreateRequest: {
                            token:match.params.token,
                            credentialId: r.id,
                            clientDataJSON: base64url.encode(r.response.clientDataJSON),
                            attestationObject: base64url.encode(r.response.attestationObject),
                        }
                    })
                    .then( () => {
                        setRegistered(true);
                        setMessage("Registration complete");
                    })
                    .catch( (ee) => {
                        console.log(ee);
                        setMessage('Signup error encountered: '+ ee.status);
                    });
                }).catch( (ex) => {
                    console.log(ex);
                    setMessage('Registration error encountered: ' + ex.message);
                });
            })
            .catch( (e) => {
                if (e.status === 404) {
                    setMessage('Invalid registration link.');
                } else {
                    console.log(e);
                    setMessage('Registration error encountered: '+ e.status);
                }
            });
    },[match, props]);

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                {registered ?
                    <>
                        <p>Registration complete - log in using the button below.</p>
                        <Button
                            component={Link} to='/login'
                            variant="contained"
                            color="primary">
                            Log-in
                        </Button>
                    </>
                    :
                    <>
                    <p>{message}</p>
                    </>
                }
            </div>
        </Container>
    );
}

export default Register;
