/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EntityRequest,
    EntityRequestFromJSON,
    EntityRequestToJSON,
    EntityResource,
    EntityResourceFromJSON,
    EntityResourceToJSON,
} from '../models';

export interface CreateTrustRequest {
    request: EntityRequest;
}

export interface ShowTrustRequest {
    trustId: number;
}

export interface UpdateTrustRequest {
    trustId: number;
    request: EntityRequest;
}

/**
 * 
 */
export class TrustControllerApi extends runtime.BaseAPI {

    /**
     * Create an entity
     */
    async createTrustRaw(requestParameters: CreateTrustRequest): Promise<runtime.ApiResponse<EntityResource>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling createTrust.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/trusts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityResourceFromJSON(jsonValue));
    }

    /**
     * Create an entity
     */
    async createTrust(requestParameters: CreateTrustRequest): Promise<EntityResource> {
        const response = await this.createTrustRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a list of entities
     */
    async listTrustsRaw(): Promise<runtime.ApiResponse<Array<EntityResource>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/trusts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EntityResourceFromJSON));
    }

    /**
     * Retrieve a list of entities
     */
    async listTrusts(): Promise<Array<EntityResource>> {
        const response = await this.listTrustsRaw();
        return await response.value();
    }

    /**
     * Retrieve Entity information
     */
    async showTrustRaw(requestParameters: ShowTrustRequest): Promise<runtime.ApiResponse<EntityResource>> {
        if (requestParameters.trustId === null || requestParameters.trustId === undefined) {
            throw new runtime.RequiredError('trustId','Required parameter requestParameters.trustId was null or undefined when calling showTrust.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/trusts/{trustId}`.replace(`{${"trustId"}}`, encodeURIComponent(String(requestParameters.trustId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityResourceFromJSON(jsonValue));
    }

    /**
     * Retrieve Entity information
     */
    async showTrust(requestParameters: ShowTrustRequest): Promise<EntityResource> {
        const response = await this.showTrustRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the entity
     */
    async updateTrustRaw(requestParameters: UpdateTrustRequest): Promise<runtime.ApiResponse<EntityResource>> {
        if (requestParameters.trustId === null || requestParameters.trustId === undefined) {
            throw new runtime.RequiredError('trustId','Required parameter requestParameters.trustId was null or undefined when calling updateTrust.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling updateTrust.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/trusts/{trustId}`.replace(`{${"trustId"}}`, encodeURIComponent(String(requestParameters.trustId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EntityRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityResourceFromJSON(jsonValue));
    }

    /**
     * Update the entity
     */
    async updateTrust(requestParameters: UpdateTrustRequest): Promise<EntityResource> {
        const response = await this.updateTrustRaw(requestParameters);
        return await response.value();
    }

}
