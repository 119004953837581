/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EntityClassification,
    EntityClassificationFromJSON,
    EntityClassificationFromJSONTyped,
    EntityClassificationToJSON,
    OrganisationResource,
    OrganisationResourceFromJSON,
    OrganisationResourceFromJSONTyped,
    OrganisationResourceToJSON,
    ProjectResource,
    ProjectResourceFromJSON,
    ProjectResourceFromJSONTyped,
    ProjectResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface EntityResource
 */
export interface EntityResource {
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    city?: string;
    /**
     * 
     * @type {EntityClassification}
     * @memberof EntityResource
     */
    classification?: EntityClassification;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    country?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityResource
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    name?: string;
    /**
     * 
     * @type {OrganisationResource}
     * @memberof EntityResource
     */
    organisation?: OrganisationResource;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    postalCode?: string;
    /**
     * 
     * @type {Array<ProjectResource>}
     * @memberof EntityResource
     */
    projects?: Array<ProjectResource>;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    registrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    state?: string;
}

export function EntityResourceFromJSON(json: any): EntityResource {
    return EntityResourceFromJSONTyped(json, false);
}

export function EntityResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'classification': !exists(json, 'classification') ? undefined : EntityClassificationFromJSON(json['classification']),
        'country': !exists(json, 'country') ? undefined : json['country'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organisation': !exists(json, 'organisation') ? undefined : OrganisationResourceFromJSON(json['organisation']),
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(ProjectResourceFromJSON)),
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function EntityResourceToJSON(value?: EntityResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'classification': EntityClassificationToJSON(value.classification),
        'country': value.country,
        'id': value.id,
        'name': value.name,
        'organisation': OrganisationResourceToJSON(value.organisation),
        'postalCode': value.postalCode,
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(ProjectResourceToJSON)),
        'registrationNumber': value.registrationNumber,
        'state': value.state,
    };
}


