import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            Fidoocle &trade; Compliance Service {' v' + process.env.REACT_APP_VERSION + ' - Copyright '}&#169; &nbsp;
            <Link color="inherit" href="https://fidoocle.com/">
                Fidoocle LTD
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Copyright;
