/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectResource,
    ProjectResourceFromJSON,
    ProjectResourceFromJSONTyped,
    ProjectResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface FolderResource
 */
export interface FolderResource {
    /**
     * 
     * @type {number}
     * @memberof FolderResource
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FolderResource
     */
    name?: string;
    /**
     * 
     * @type {ProjectResource}
     * @memberof FolderResource
     */
    project?: ProjectResource;
}

export function FolderResourceFromJSON(json: any): FolderResource {
    return FolderResourceFromJSONTyped(json, false);
}

export function FolderResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FolderResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'project': !exists(json, 'project') ? undefined : ProjectResourceFromJSON(json['project']),
    };
}

export function FolderResourceToJSON(value?: FolderResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'project': ProjectResourceToJSON(value.project),
    };
}


