/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FolderResource,
    FolderResourceFromJSON,
    FolderResourceFromJSONTyped,
    FolderResourceToJSON,
    UserResource,
    UserResourceFromJSON,
    UserResourceFromJSONTyped,
    UserResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocumentResource
 */
export interface DocumentResource {
    /**
     * 
     * @type {FolderResource}
     * @memberof DocumentResource
     */
    folder?: FolderResource;
    /**
     * 
     * @type {string}
     * @memberof DocumentResource
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentResource
     */
    tagId?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentResource
     */
    uid?: string;
    /**
     * 
     * @type {UserResource}
     * @memberof DocumentResource
     */
    uploader?: UserResource;
}

export function DocumentResourceFromJSON(json: any): DocumentResource {
    return DocumentResourceFromJSONTyped(json, false);
}

export function DocumentResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'folder': !exists(json, 'folder') ? undefined : FolderResourceFromJSON(json['folder']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tagId': !exists(json, 'tagId') ? undefined : json['tagId'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'uploader': !exists(json, 'uploader') ? undefined : UserResourceFromJSON(json['uploader']),
    };
}

export function DocumentResourceToJSON(value?: DocumentResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'folder': FolderResourceToJSON(value.folder),
        'name': value.name,
        'tagId': value.tagId,
        'uid': value.uid,
        'uploader': UserResourceToJSON(value.uploader),
    };
}


