/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserResource,
    UserResourceFromJSON,
    UserResourceFromJSONTyped,
    UserResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginationResourceUserResource
 */
export interface PaginationResourceUserResource {
    /**
     * 
     * @type {Array<UserResource>}
     * @memberof PaginationResourceUserResource
     */
    data?: Array<UserResource>;
    /**
     * 
     * @type {number}
     * @memberof PaginationResourceUserResource
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResourceUserResource
     */
    pageSize?: number;
}

export function PaginationResourceUserResourceFromJSON(json: any): PaginationResourceUserResource {
    return PaginationResourceUserResourceFromJSONTyped(json, false);
}

export function PaginationResourceUserResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationResourceUserResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(UserResourceFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
    };
}

export function PaginationResourceUserResourceToJSON(value?: PaginationResourceUserResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(UserResourceToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
    };
}


