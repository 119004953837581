import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {FolderControllerApi, ProjectControllerApi} from "./api";
import {checkError, errOptions} from "./util";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FolderIcon from "@material-ui/icons/Folder";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NewFolderIcon from "@material-ui/icons/CreateNewFolder";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import Folder from "./Folder";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import OKCancelDialog from "./OKCancelDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function Project(props) {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar()
    const [open, setOpen] = useState(false);
    const [openFolderEditor, setOpenFolderEditor] = useState(false);
    const [openFolderAdd, setOpenFolderAdd] = useState(false);
    const [folders, setFolders] = useState(null);
    const [folderName, setFolderName] = useState("");
    const [mainFolderName, setMainFolderName] = useState(props.project.name);

    useEffect(() => {
        const api = new FolderControllerApi(props.conf);
        api.listFolders( {projectId: props.project.id})
            .then( (t) => {
                setFolders(t);
            })
            .catch();
    },[]);

    const handleAddFolder = () => {
        setOpenFolderAdd(false);
        if (folderName == null || folderName.length === 0) {
            enqueueSnackbar('Invalid folder name', errOptions);
        } else {
            if (folders.find( (f) => { return f.name === folderName})) {
                enqueueSnackbar('Folder '+ folderName+ ' already exists', errOptions);
                return;
            }

            const api = new FolderControllerApi(props.conf);
            api.createFolder( { request: {
                    name: folderName,
                    mainFolderId: props.project.id}})
                .then( (r) => {
                    setFolders([r,...folders]);
                    setFolderName("");
                })
                .catch( (e) => checkError(e,history, () =>
                    enqueueSnackbar('Failed to add folder: ' + e.statusText,
                        errOptions)));
        }
    }

    const handleUpdateFolder = () => {
        setOpenFolderEditor(false);
        if (props.projects.find( (f) => { return f.name === mainFolderName})) {
            enqueueSnackbar('Folder '+ mainFolderName+ ' already exists', errOptions);
            setMainFolderName(props.project.name);
            return;
        }

        const api = new ProjectControllerApi(props.conf);
        api.updateMainFolder({
            folderId: props.project.id,
            request: {
                name: mainFolderName,
                entityId: props.project.trust.id
            }
        })
            .then((r) => {

            })
            .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to update folder: ' + e.statusText,
                    errOptions)));

    };

    const folderNameEditFragment = () => {
        return (<>
            <TextField
                variant="outlined"
                id={props.project.id +"name"}
                name="name"
                label="Main Folder Name"
                value={mainFolderName}
                onChange={(e) => setMainFolderName(e.target.value)}
            />
        </>);
    };

    const folderAddFragment = () => {
        return (<>
            <TextField
                variant="outlined"
                id={props.project.id +"name"}
                name="name"
                label="Folder Name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
            />
        </>);
    };

    return (<>
            <OKCancelDialog title="Change main folder name"
                            description=""
                            render={folderNameEditFragment}
                            open={openFolderEditor}
                            handleClose={() => setOpenFolderEditor(false)}
                            handleAccept={handleUpdateFolder}/>

            <OKCancelDialog title="Add sub folder"
                            description=""
                            render={folderAddFragment}
                            open={openFolderAdd}
                            handleClose={() => setOpenFolderAdd(false)}
                            handleAccept={handleAddFolder}/>

            <ListItem key={props.project.id}>
                <ListItemIcon><FolderIcon/></ListItemIcon>
                <ListItemText primary={mainFolderName} />
                <IconButton aria-label="add-sub-folder" onClick={() => {
                    setOpenFolderAdd(true);
                }}>
                    <NewFolderIcon/>
                </IconButton>
                <IconButton aria-label="edit-main-folder" onClick={() => {
                    setOpenFolderEditor(true);
                }}>
                    <EditIcon/>
                </IconButton>

                <IconButton aria-label="expand-main-folder" onClick={() => {
                    setOpen(!open);
                }}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>

            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.nested}>
                    {folders !== null && folders.map((folder) =>
                        <Folder conf={props.conf} key={folder.id} data-key={folder.id} folder={folder}
                                folders={folders} tags={props.tags}/> )
                    }
                </List>
            </Collapse>
        </>
    );
}

export default Project;
