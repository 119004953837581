/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SignatureRequestResource,
    SignatureRequestResourceFromJSON,
    SignatureRequestResourceToJSON,
} from '../models';

export interface ListDocumentSignaturesRequest {
    documentId: string;
}

/**
 * 
 */
export class DocumentSignatureRequestApi extends runtime.BaseAPI {

    /**
     * listDocumentSignatures
     */
    async listDocumentSignaturesRaw(requestParameters: ListDocumentSignaturesRequest): Promise<runtime.ApiResponse<Array<SignatureRequestResource>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling listDocumentSignatures.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/document-signature-requests/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignatureRequestResourceFromJSON));
    }

    /**
     * listDocumentSignatures
     */
    async listDocumentSignatures(requestParameters: ListDocumentSignaturesRequest): Promise<Array<SignatureRequestResource>> {
        const response = await this.listDocumentSignaturesRaw(requestParameters);
        return await response.value();
    }

}
