/* tslint:disable */
/* eslint-disable */
/**
 * Fidoocle API
 * Secure signing API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@fidoocle.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DocumentResource,
    DocumentResourceFromJSON,
    DocumentResourceToJSON,
    DocumentUpdateRequest,
    DocumentUpdateRequestFromJSON,
    DocumentUpdateRequestToJSON,
} from '../models';

export interface CreateDocumentRequest {
    file?: Blob;
    tagId?: number;
    folderId?: number;
}

export interface ListDocumentsRequest {
    folder: number;
    page?: number;
    sort?: string;
}

export interface ShowDocumentRequest {
    document: string;
}

export interface SignerPageRequest {
    uid: string;
}

export interface UpdateDocumentRequest {
    uid: string;
    resource: DocumentUpdateRequest;
}

/**
 * 
 */
export class DocumentControllerApi extends runtime.BaseAPI {

    /**
     * Create a new document
     */
    async createDocumentRaw(requestParameters: CreateDocumentRequest): Promise<runtime.ApiResponse<DocumentResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.tagId !== undefined) {
            formParams.append('tagId', requestParameters.tagId as any);
        }

        if (requestParameters.folderId !== undefined) {
            formParams.append('folderId', requestParameters.folderId as any);
        }

        const response = await this.request({
            path: `/api/v1/documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentResourceFromJSON(jsonValue));
    }

    /**
     * Create a new document
     */
    async createDocument(requestParameters: CreateDocumentRequest): Promise<DocumentResource> {
        const response = await this.createDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch all the documents
     */
    async listDocumentsRaw(requestParameters: ListDocumentsRequest): Promise<runtime.ApiResponse<Array<DocumentResource>>> {
        if (requestParameters.folder === null || requestParameters.folder === undefined) {
            throw new runtime.RequiredError('folder','Required parameter requestParameters.folder was null or undefined when calling listDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.folder !== undefined) {
            queryParameters['folder'] = requestParameters.folder;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentResourceFromJSON));
    }

    /**
     * Fetch all the documents
     */
    async listDocuments(requestParameters: ListDocumentsRequest): Promise<Array<DocumentResource>> {
        const response = await this.listDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * show a document
     */
    async showDocumentRaw(requestParameters: ShowDocumentRequest): Promise<runtime.ApiResponse<DocumentResource>> {
        if (requestParameters.document === null || requestParameters.document === undefined) {
            throw new runtime.RequiredError('document','Required parameter requestParameters.document was null or undefined when calling showDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/documents/{document}`.replace(`{${"document"}}`, encodeURIComponent(String(requestParameters.document))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentResourceFromJSON(jsonValue));
    }

    /**
     * show a document
     */
    async showDocument(requestParameters: ShowDocumentRequest): Promise<DocumentResource> {
        const response = await this.showDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get pdf signer page
     */
    async signerPageRaw(requestParameters: SignerPageRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling signerPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/documents/{uid}/signerpage`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters.uid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get pdf signer page
     */
    async signerPage(requestParameters: SignerPageRequest): Promise<Blob> {
        const response = await this.signerPageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a document
     */
    async updateDocumentRaw(requestParameters: UpdateDocumentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling updateDocument.');
        }

        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling updateDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/documents/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters.uid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentUpdateRequestToJSON(requestParameters.resource),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a document
     */
    async updateDocument(requestParameters: UpdateDocumentRequest): Promise<void> {
        await this.updateDocumentRaw(requestParameters);
    }

}
