import React, {useState, useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import KeyIcon from '@material-ui/icons/VpnKey';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';

import {
    useHistory
} from "react-router-dom";
import {FidoControllerApi} from './api';
import {checkError, errOptions, getPubKeyFromParams} from "./util";
import Typography from "@material-ui/core/Typography";
import base64url from "./base64";
import TextField from "@material-ui/core/TextField";
import AlertDialog from "./AlertDialog";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    warningButton: {
        backgroundColor: theme.palette.warning.main,
    },
}));

function SecurityKey(props) {
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleConfirmDelete = () => {
        setOpenConfirm(true);
    };

    const handleClose = () => {
        setOpenConfirm(false);
    };

    const handleAccept = () => {
        setOpenConfirm(false);
        props.onRemove(props.securitykey.id);
    };

    return (<>
            <AlertDialog title="Remove security key?" open={openConfirm} handleClose={handleClose}
                         handleAccept={handleAccept}/>
            <ListItem>
                <ListItemIcon><KeyIcon/></ListItemIcon>
                <ListItemText>{props.securitykey.name}</ListItemText>
                <ListItemSecondaryAction>
                    <IconButton onClick={handleConfirmDelete}>
                        <DeleteIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
}

function SecurityKeys(props) {
    const classes = useStyles();
    const [keys, setKeys] = useState(null);
    const [keyName, setKeyName] = useState("");
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    const api = new FidoControllerApi(props.conf);

    useEffect(() => {
        api.listFIDODevices()
            .then((devices) => {
                setKeys(devices);
            })
            .catch(e => {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to request key list: ' + e.statusText,
                        errOptions))
            });
    }, []);

    const handleRemoveKey = (id) => {
        if (keys.length < 2) {
            enqueueSnackbar('Removing of all security keys not allowed',
                errOptions);
            return;
        }
        api.removeSecurityKey({id: id})
            .then( () => {
                    setKeys(keys.filter((k) => {
                        return k.id !== id
                    }));
                    enqueueSnackbar('Security key removed.',{autoHideDuration: 3000, variant: 'success'});
                }
            )
            .catch(e => {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to remove key: ' + e.statusText,
                        errOptions))
            });
    };

    const handleAddKey = () => {
        if (keyName == null || keyName.length < 1) {
            enqueueSnackbar('Invalid key name',
                errOptions);
            return;
        }
        api.getFIDOParameters()
            .then((p) => {
                let pub = getPubKeyFromParams(p);
                navigator.credentials.create({
                    publicKey: pub,
                }).then((r) => {
                    api.registerFIDODevice({
                        fido2DeviceRegisterRequest: {
                            keyName: keyName,
                            challengeToken: p.token,
                            credentialId: r.id,
                            clientDataJSON: base64url.encode(r.response.clientDataJSON),
                            attestationObject: base64url.encode(r.response.attestationObject),
                        }
                    })
                        .then((d) => {
                            setKeys([...keys, d]);
                            setKeyName("");
                        })
                        .catch(e => {
                            checkError(e, history, () =>
                                enqueueSnackbar('Failed to register security key: ' + e.statusText,
                                    errOptions))
                        });
                }).catch(e => {
                    checkError(e, history, () =>
                        enqueueSnackbar('Failed to create security key credential: ' + e.message,
                            errOptions))
                });
            })
            .catch(e => {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to retrieve key parameters: ' + e.statusText,
                        errOptions))
            });
    };

    return (
        <>
            <Grid container item justify="center">
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Registered security keys
                    </Typography>
                    <List>{keys !== null && keys.map((key) =>
                        <SecurityKey key={key.id} securitykey={key} onRemove={handleRemoveKey}/>)}
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="name"
                        name="name"
                        label="Key Name"
                        value={keyName}
                        onChange={(e) => setKeyName(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleAddKey}>
                        Register security key
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default SecurityKeys;
